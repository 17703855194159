import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { isoToDate } from '../../../utils/FormatDate'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import {
  DeleteCompanyReportsMutation,
  DeleteCompanyReportsMutationVariables,
  GetUrlForS3ObjectQuery,
  useDeleteCompanyReportsMutation,
  useGetCompanyLatestReportQuery,
  useGetCompanyMisReportsQuery,
  useGetUrlForS3ObjectQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import Toast, { ToastType } from '../../../utils/toast'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { hexToRGB } from '../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import ReportsModal from './ReportsModal'
import { MisReportType } from './AddReportsForm'

export function ReportNameRender(data: any) {
  return (
    <div className='pt-2'>
      <Link
        className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary'
        to={`/company/misreport/${data.data.type}/${
          data.data.companyId || data.data.hissaCompanyId
        }/${data.data.reportId}`}
      >
        <p className='fw-semibold fs-6'>{data.data.reportName}</p>
      </Link>
    </div>
  )
}

export function ReportDateRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.reportDate ? isoToDate(data.data.reportDate) : '-'}</p>
    </div>
  )
}

export function ReportTagsRender(data: any) {
  return (
    <div className='py-2'>
      {data.data.tags &&
        data.data.tags.map((tag: string, index: number) => {
          return (
            <span
              key={index}
              className={`badge fs-9 fw-semibold mb-1 me-2 `}
              style={{
                background: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 0.2),
                color: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 1),
              }}
            >
              {tag}
            </span>
          )
        })}
    </div>
  )
}

export function ReportFileRender(data: any) {
  const [state, setState] = useState(false)
  const [bucket, setBucket] = useState<any>()
  const [key, setKey] = useState('')

  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    { input: { key, bucket: bucket } },
    {
      enabled: state,
    }
  )

  if (query.isFetched && state === true) {
    window.open(query.data?.urlForS3Object || '')
    setState(false)
  }

  function handleClick(item: any) {
    setBucket(process.env.REACT_APP_S3_INVESTOR_BUCKET || '')
    setKey(item.reportFile)
    setState(true)
  }
  const fileName = data.data.reportFile.split('/')
  return (
    <div className='pt-2'>
      <div
        className='d-flex align-items-center'
        onClick={() => {
          data.data.reportFile !== '' && handleClick(data.data)
        }}
      >
        <div className='symbol symbol-35px me-5' style={{ cursor: 'pointer' }}>
          {data.data.reportFile !== '' && (
            <img
              src={
                data.data.reportFile.toLowerCase().endsWith('.pdf')
                  ? toAbsoluteUrl('/media/svg/files/pdf.svg')
                  : toAbsoluteUrl('/media/svg/files/doc.svg')
              }
              alt=''
            />
          )}
        </div>
        <span className='text-wrap fs-base cursor-pointer'>
          <p
            className='text-truncate mb-0'
            data-toggle='tooltip'
            data-placement='top'
            title={fileName[fileName.length - 1]}
            style={{ maxWidth: '190px' }}
          >
            {fileName[fileName.length - 1]}
          </p>
        </span>
      </div>
    </div>
  )
}

export function ReportsActionRender(data: any) {
  const [showDeleteReport, setShowDeleteReport] = useState(false)
  const [showEditReport, setShowEditReport] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data.data.getCompanyMisReports])

  const [loading, setLoading] = useState<boolean>(false)
  const hissaCompanyId = data.data.hissaCompanyId
  const mutateDeleteReport = useDeleteCompanyReportsMutation<DeleteCompanyReportsMutation, Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        responseData: DeleteCompanyReportsMutation,
        _variables: DeleteCompanyReportsMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: {
            hissaCompanyId: hissaCompanyId || data.data.companyId || '',
            type: data.data.type,
            id: Number(data.data.companyId) || 0,
          },
        }
        queryClient.invalidateQueries(useGetCompanyMisReportsQuery.getKey(compKey))
        queryClient.invalidateQueries(useGetCompanyLatestReportQuery.getKey(compKey))
        Toast('Report Deleted Successfully!', ToastType.success)
        setShowDeleteReport(false)
        setLoading(false)
        if (data.data.isLastRecord) {
          navigate(
            `/company/misreport/${data.data.type}/${
              data.data.companyId || data.data.hissaCompanyId
            }`,
            { replace: true }
          )
        }
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        setShowDeleteReport(false)
        setLoading(false)
      },
    },
    {}
  )

  function handleDeleteReport() {
    setLoading(true)
    mutateDeleteReport.mutate({
      input: {
        id: Number(data.data.reportId),
        companyId: Number(data.data.companyId),
        hissaCompanyId: data.data.hissaCompanyId,
        companyType: data.data.type,
        companyName: data.data.companyName,
      },
    })
  }

  const options = ['Delete Report', 'Edit Report']
  const actionMenu = () => {
    return options.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={() => {
            if (option === 'Delete Report') {
              setShowDeleteReport(true)
            } else if (option === 'Edit Report') {
              setShowEditReport(true)
            }
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal cursor-pointer'>{option}</span>
        </label>
      )
    })
  }

  return (
    <div className='px-0'>
      {data.data.investorName !== 'Total' && data.data.dateOfInvestment !== null && (
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      )}
      {showDeleteReport && (
        <Modal
          id='kt_modal_add_inv'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-500px '
          show={showDeleteReport}
          onHide={() => setShowDeleteReport(false)}
        >
          <Modal.Body className='p-9'>
            <div className='card'>
              <div className='card-body p-0'>
                <h2 className='fw-bolder text-gray-heading pb-4'>Delete Report</h2>
                <div className='fs-5 pb-6'>Are you sure you want to remove this report?</div>

                <div className='d-flex col justify-content-between pt-6'>
                  <button
                    className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                    onClick={() => setShowDeleteReport(false)}
                  >
                    <span className='indicator-label'>Cancel</span>
                  </button>
                  <button
                    type='submit'
                    className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
                    disabled={loading}
                    onClick={() => handleDeleteReport()}
                  >
                    {!loading && 'Delete'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showEditReport && (
        <ReportsModal
          companyData={data.data.companyData}
          closeModal={() => setShowEditReport(false)}
          showModal={showEditReport}
          isEdit={true}
          reportData={
            {
              name: data.data.reportName,
              date: data.data.reportDate,
              tags: data.data.tags,
              report: data.data.reportFile?.split('/')?.pop() || '',
            } as MisReportType
          }
          reportId={data.data.reportId}
        />
      )}

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
        data-kt-menu='true'
      >
        {actionMenu()}
      </div>
    </div>
  )
}
