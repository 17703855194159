import { useFormik } from 'formik'
import * as Yup from 'yup'
import { queryClient } from '../../../../queries/client'
import { useGetNewWatchlistsQuery } from '../../../../generated/graphql'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'

type DateProps = {
  startDate: Date
  endDate: Date
  setStartDate: (value: Date) => void
  setEndDate: (value: Date) => void
}

type DateRange = {
  startDate: string
  endDate: string
}

export function DateFilterForLatestActivity(props: DateProps) {
  let date = new Date()
  let maxDate = date.toISOString().split('T')[0]
  let initEndDatetime = date.setDate(date.getDate())
  let initStartDatetime = date.setDate(date.getDate() - 90)

  const DateRangeSchemas = Yup.object().shape({
    startDate: Yup.string().required().label('Start Date'),
    endDate: Yup.string().required().label('End Date'),
  })

  const formik = useFormik<DateRange>({
    initialValues: {
      startDate: props.startDate.toISOString().split('T')[0],
      endDate: props.endDate.toISOString().split('T')[0],
    },
    validationSchema: DateRangeSchemas,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      props.setStartDate(new Date(values.startDate))
      props.setEndDate(new Date(values.endDate))
      queryClient.invalidateQueries(useGetNewWatchlistsQuery.getKey())
      MenuComponent.hideDropdowns(undefined)
    },
  })

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold mw-300px h-325px p-9 me-3'
      data-kt-menu='true'
    >
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='border-gray-400'>
          <div
            className='card mh-300px my-4'
            style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
          >
            <label className='form-label required'>Start Date</label>
            <input
              type='date'
              max={formik.values.endDate || maxDate}
              className='form-control form-control-solid'
              {...formik.getFieldProps('startDate')}
              onChange={(e) => {
                if (e.target.value) {
                  formik.setFieldValue('startDate', e.target.value)
                  props.setStartDate(new Date(e.target.value))
                  queryClient.invalidateQueries(useGetNewWatchlistsQuery.getKey())
                }
              }}
            />
            <div className='text-danger'>
              {formik.touched.startDate && formik.errors.startDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.startDate}</div>
                </div>
              )}
            </div>

            <label className='form-label required pt-4'>End Date</label>
            <input
              type='date'
              min={formik.values.startDate || ''}
              max={maxDate}
              {...formik.getFieldProps('endDate')}
              onChange={(e) => {
                if (e.target.value) {
                  formik.setFieldValue('endDate', e.target.value)
                  props.setEndDate(new Date(e.target.value))
                  queryClient.invalidateQueries(useGetNewWatchlistsQuery.getKey())
                }
              }}
              className='form-control form-control-solid'
            />
            <div className='text-danger mt-2'>
              {formik.touched.endDate && formik.errors.endDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.endDate}</div>
                </div>
              )}
            </div>
            <div className='col-12 d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-secondary mt-4'
                onClick={() => {
                  props.setStartDate(new Date(initStartDatetime))
                  props.setEndDate(new Date(initEndDatetime))
                  formik.setFieldValue('startDate', new Date(initStartDatetime))
                  formik.setFieldValue('endDate', new Date())
                  formik.resetForm()
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
