import { FC, useState, createContext, useContext } from 'react'
import * as authHelper from './AuthHelpers'
import { WithChildren } from '../../../../_metronic/helpers'
import { removeItem } from '../../../../utils/LocalStorageUtils'
import { usePortfolioStore } from '../../../../store/portfolioFilterDataStore'
import { useAuthorizationStore } from '../../../../store/useAuthStore'
import { useLatestActivityStore } from '../../../../store/latestActivityDateStore'
import { useUserNameStore } from '../../../../store/UserNameStore'

type AuthContextProps = {
  auth: string | undefined
  saveAuth: (auth: string | undefined, defaultAccount: boolean) => void
  logout: () => void
  isDefaultAccount: boolean
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  logout: () => {},
  isDefaultAccount: false,
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<string | undefined>(authHelper.getAuth())
  const [isDefault, setIsDefault] = useState<boolean>(false)
  const useFilterStore = usePortfolioStore()
  const useActivityStore = useLatestActivityStore()
  const userStore = useAuthorizationStore()
  const userNameStore = useUserNameStore()
  const saveAuth = (auth: string | undefined, isDefaultBoolean: boolean) => {
    setAuth(auth)
    setIsDefault(isDefaultBoolean)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined, false)
    removeItem('userRoles')
    removeItem('userInvestors')
    useFilterStore.reset()
    useActivityStore.reset()
    userNameStore.reset()
    userStore.clearAuthorization()
    localStorage.clear()
    // removeItem('iFrameToken')
  }
  const isDefaultAccount = isDefault
  return (
    <AuthContext.Provider value={{ auth, saveAuth, logout, isDefaultAccount }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, useAuth }
