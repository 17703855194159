import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { MisDataType } from '../../../generated/graphql'
import { isoToDate } from '../../../utils/FormatDate'
import { ShowTags } from '../../../utils/Tags'

type Props = {
  data: MisDataType
}

export function ReportsPageHeader({ data }: Props) {
  return (
    <div
      className='card h-md-85px h-full p-3 bg-growth-card text-white pb-3'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='row'>
        <div className='col-12 col-md-6 card h-full bg-growth-card pb-4 pt-1 pb-md-0'>
          <div className={'border-2 border-end border-gray-600 px-4'}>
            <div className='d-flex justify-content-between my-2'>
              <p className='fs-5 p-0 m-0 '>
                Report Name
                {' : '}
              </p>
              <p
                className='fs-4 fw-bold p-0 m-0 text-truncate'
                data-toggle='tooltip'
                style={{ maxWidth: '160px' }}
              >
                {data.reportName}
              </p>
            </div>
            <div className='d-flex justify-content-between'>
              <p className='fs-5 d-flex align-items-center p-0 m-0 '>
                Report Date
                {' : '}
              </p>
              <p
                className='fs-4 fw-bold p-0 m-0 text-truncate'
                data-toggle='tooltip'
                style={{ maxWidth: '160px' }}
              >
                {data.reportDate ? isoToDate(data.reportDate) : '-'}
              </p>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 card h-full bg-growth-card pb-4 pt-1 pb-md-0'>
          <div className={'px-4'}>
            <div className='d-flex justify-content-between my-2'>
              <p className='fs-5 p-0 m-0 '>
                Report File
                {' : '}
              </p>
              {data.fileUrl !== '' && (
                <img
                  src={toAbsoluteUrl('/media/svg/files/excel.svg')}
                  alt=''
                  className='fs-4 fw-bold p-0 m-0 text-truncate cursor-pointer'
                  style={{ maxWidth: '24px', maxHeight: '24px' }}
                  onClick={() => {
                    window.open(data.fileUrl || '')
                  }}
                />
              )}
            </div>
            <div className='d-flex justify-content-between'>
              <p className='fs-5 d-flex align-items-center p-0 m-0 '>
                Tags
                {' : '}
              </p>
              <p
                className='fs-4 fw-bold p-0 m-0 text-truncate'
                data-toggle='tooltip'
                style={{ maxWidth: '160px' }}
              >
                {ShowTags(data.tags as string[])}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
