import { useFormik } from 'formik'
import { ChangeEvent, useEffect, useState } from 'react'
import _ from 'lodash'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import Select from 'react-select'
import {
  IAddLedgerTransactionData,
  addLedgerTransactionInits,
  addLedgerTransactionSchema,
} from './AddLedgerTransactionHelper'
import {
  AddLedgerTransactionMutation,
  AddLedgerTransactionMutationVariables,
  CapitalLedgerType,
  EditLedgerTransactionMutation,
  EditLedgerTransactionMutationVariables,
  FundStructure,
  LedgerTransaction,
  PartnerGroup,
  useAddLedgerTransactionMutation,
  useEditLedgerTransactionMutation,
  useGetCapitalLedgerTransactionQuery,
  useGetFundStructureQuery,
} from '../../../../generated/graphql'
import { SelectOptionType } from '../../../../utils/MultiSelect'
import { selectCustomStyles } from '../../../../utils/Select'
import { isoToDate } from '../../../../utils/FormatDate'
import { KTSVG } from '../../../../_metronic/helpers'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'

type Props = {
  investorId: number
  fundStructureId: number
  handleClose: VoidFunction
  partnerGroups: PartnerGroup[]
  isEdit: boolean
  data?: LedgerTransaction
  fundStructure: FundStructure
}

type Fund = {
  fundUnitId: number
  noOfUnits: number
}

type GroupTransaction = {
  groupName: string
  transactionAmount: number
  transactionDate: string
  groupId: number
  fundUnitName: string
  fundUnitId: number
  noOfUnits: number
}

type SeledtedGroup = {
  groupName: string
  fundName: string
}

function AddLedgerTransactionForm(props: Props) {
  const initialData: IAddLedgerTransactionData = {
    closeDate: props.data?.closeDate?.split('T')[0],
    finalAmount: Number(props.data?.finalAmount),
    type:
      CapitalLedgerType.CapitalCall === props.data?.type
        ? CapitalLedgerType.CapitalCall
        : CapitalLedgerType.CapitalDistribution,
    isAddGroupTransaction: props.data?.ledgerTransactionTable?.length! > 0 ? true : false,
    transactionAmount: '',
    transactionDate: '',
    groupName: '',
    fundName: '',
    note: props.data?.notes || '',
    currentValue: Number(props.data?.currentValue),
    transactionName: props.data?.transactionName || '',
  }

  const [data, setData] = useState<IAddLedgerTransactionData>(
    _.cloneDeep(props.isEdit ? initialData : addLedgerTransactionInits)
  )
  const transactionTypes: SelectOptionType[] = [
    { label: 'Capital Call', value: 'CapitalCall' },
    { label: 'Capital Distribution', value: 'CapitalDistribution' },
  ]
  const [partnerGroupsTypes, setPartnersGroupTypes] = useState<SelectOptionType[]>([
    { label: '--Select--', value: '' },
  ])
  const [fundTypes, setFundTypes] = useState<SelectOptionType[]>([
    { label: '--Select--', value: '' },
  ])
  const [selectedGroupFunds, setSelectedGroupFunds] = useState<SeledtedGroup[]>([])
  const [selectedPartner, setSelectedPartner] = useState<SelectOptionType[]>([])
  const [selectedPartnerNames, setSelectedPartnerNames] = useState<string[]>([])
  const [selectedFund, setSelectedFund] = useState<SelectOptionType[]>([])
  const [isEditContribution, setIsEditContribution] = useState<boolean>(false)
  const [amount, setAmount] = useState<number>(0)
  const [dateError, setDateError] = useState<boolean>(false)
  const [amountError, setAmountError] = useState<boolean>(false)
  const [transactionAmountError, setTransactionAmountError] = useState<boolean>(false)
  const [partnerGroupList, setPartnerGroupList] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [isEditTransaction, setIsEditTransaction] = useState(false)

  const [nameList, setNameList] = useState<SelectOptionType[]>([
    { value: 'Add New', label: 'Add New' },
    { value: 'Sponsor Call', label: 'Sponsor Call' },
    { value: 'First Call', label: 'First Call' },
  ])
  const [customTag, setCustomTag] = useState<boolean>(false)
  const tagsObjList: { value?: string; label?: string }[] = []
  const [tagValues, setTagValues] = useState<any>(
    props.data?.transactionName
      ? { value: props.data?.transactionName, label: props.data?.transactionName }
      : ''
  )
  const [inputText, setInputText] = useState<string>('')
  const [disable, setDisable] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [availableAmount, setAvailableAmount] = useState<number>(0)
  const [unitError, setUnitError] = useState<string>('')

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [props, partnerGroupList])

  useEffect(() => {
    if (!props.isEdit && props.partnerGroups?.length > 0) {
      props.partnerGroups.forEach((group: PartnerGroup) => {
        const groupObj = { label: group.name!, value: group.id }
        const index = partnerGroupsTypes.findIndex((object) => object.value === group.id)
        if (index === -1 && !selectedPartnerNames.includes(group.name!))
          partnerGroupsTypes
            ? setPartnersGroupTypes((prev: any) => [...prev, groupObj])
            : setPartnersGroupTypes([groupObj])
      })
    }
    if (props.isEdit && props.partnerGroups?.length > 0) {
      const groupNames: any = []
      props.data?.ledgerTransactionTable?.forEach((transactionObject) => {
        groupNames.push(getGroupName(Number(transactionObject!.partnerGroupId)))
      })
      props.partnerGroups.forEach((group: PartnerGroup) => {
        const groupObj = { label: group.name!, value: group.id }
        const index = partnerGroupsTypes.findIndex((object) => object.value === group.id)
        if (index === -1)
          partnerGroupsTypes
            ? setPartnersGroupTypes((prev: any) => [...prev, groupObj])
            : setPartnersGroupTypes([groupObj])
      })
    }
  }, [props.partnerGroups])

  function getFundName(unitId: number) {
    let fundName = ''
    props.fundStructure.fundUnits?.forEach((unit: any) => {
      if (Number(unit.id) === Number(unitId)) {
        fundName = unit.name
      }
    })
    return fundName
  }

  function getNAV(unitId: number) {
    let nav = 1
    props.fundStructure.fundUnits?.forEach((unit: any) => {
      if (Number(unit.id) === unitId) nav = Number(unit.nav!)
    })
    return nav
  }

  useEffect(() => {
    const updatedList: any[] = [{ label: '--Select--', value: '' }]
    if (selectedPartner.length > 0) {
      props.partnerGroups.forEach((group: PartnerGroup) => {
        group.fundUnits?.forEach((unit: Fund | any) => {
          const existedIndex = selectedGroupFunds.findIndex(
            (object) =>
              object.groupName === selectedPartner[0].label &&
              object.fundName === getFundName(unit.fundUnitId)
          )
          if (Number(group.id) === Number(selectedPartner[0].value)) {
            const groupObj = { label: getFundName(unit.fundUnitId), value: unit.fundUnitId }
            const index = updatedList.findIndex(
              (object) => Number(object.value) === Number(unit.fundUnitId)
            )
            if (index === -1 && existedIndex === -1) updatedList.push(groupObj)
          }
        })
      })
    }
    setFundTypes(updatedList)
    if (isEditContribution === false) {
      setSelectedFund([updatedList[0]])
      formik.setFieldValue('fundName', '')
    }
  }, [selectedPartner])

  useEffect(() => {
    let currentUnits = 0
    if (selectedPartner.length > 0 && selectedFund.length > 0) {
      props.partnerGroups.forEach((group: PartnerGroup) => {
        group.fundUnits?.forEach((unit: any) => {
          if (Number(group.id) === Number(selectedPartner[0].value))
            if (Number(unit.fundUnitId) === Number(selectedFund[0].value)) {
              currentUnits = unit.noOfUnits
            }
        })
      })
      setAvailableAmount(getNAV(Number(selectedFund[0].value)) * Number(currentUnits))
    }
  }, [selectedFund])

  const handleChange = (e: any) => {
    setTagValues(e)
    if (e.value === 'Add New') {
      setCustomTag((customTag) => {
        return !customTag
      })
    }
  }

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
    if (e.target.value && e.target.value.length > 0) {
      nameList.forEach((badge: any) => {
        if (badge.value === e.target.value) {
          setDisable(true)
          setErrorMessage('Multiple tags with the same name are not allowed ')
        } else {
          setDisable(false)
          setErrorMessage('')
        }
      })
    }
  }

  function getGroupName(groupId: number) {
    const selectedPartner = props.partnerGroups.filter((partner) => Number(partner.id) === groupId)
    return selectedPartner.length > 0 ? selectedPartner[0].name : ''
  }

  useEffect(() => {
    if (props.data?.ledgerTransactionTable?.length! > 0) {
      const dataList: any = []
      let totalAmount: number = 0
      props.data?.groupTransaction?.forEach((transactionObject) => {
        const groupName = getGroupName(Number(transactionObject!.groupId))
        const fundName = getFundName(Number(transactionObject?.fundUnitId))
        const partnerObj = {
          groupName: groupName,
          transactionAmount: transactionObject?.transactionAmount,
          transactionDate: transactionObject?.transactionDate
            ? new Date(transactionObject?.transactionDate).toISOString()
            : transactionObject?.transactionDate,
          groupId: Number(transactionObject?.groupId),
          fundUnitId: Number(transactionObject?.fundUnitId),
          fundUnitName: fundName,
          noOfUnits: Number(transactionObject?.noOfUnits),
        }
        totalAmount += Number(transactionObject?.transactionAmount!)

        updateSelectedGroupFunds(groupName!, fundName)

        dataList.push(partnerObj)
        // selectedPartnerNames
        //   ? setSelectedPartnerNames((prev: any) => [...prev, groupName])
        //   : setSelectedPartnerNames([groupName!])
      })
      setAmount((prev: number) => prev + Number(totalAmount))
      setPartnerGroupList([...dataList])
    }
  }, [props.data])

  const mutateAddLedgerTransaction = useAddLedgerTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddLedgerTransactionMutation,
        _variables: AddLedgerTransactionMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(
          useGetCapitalLedgerTransactionQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructureId },
          })
        )
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey({ input: props.investorId }))

        setData(_.cloneDeep(addLedgerTransactionInits))
        Toast('Transaction Added Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const mutateEditLedgerTransaction = useEditLedgerTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: EditLedgerTransactionMutation,
        _variables: EditLedgerTransactionMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(
          useGetCapitalLedgerTransactionQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructureId },
          })
        )
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey({ input: props.investorId }))
        setData(_.cloneDeep(addLedgerTransactionInits))
        Toast('Transaction Updated Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const formik = useFormik<IAddLedgerTransactionData>({
    initialValues: data,
    validationSchema: addLedgerTransactionSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        props.isEdit
          ? mutateEditLedgerTransaction.mutate({
              input: {
                id: Number(props.data?.id),
                fundStructureId: Number(props.fundStructureId),
                investorId: Number(props.investorId),
                closeDate: new Date(data.closeDate).toISOString(),
                finalAmount: data.finalAmount,
                type: data.type,
                isPartnerGroupDataPresent: data.isAddGroupTransaction ? true : false,
                partnerGroupTransaction:
                  data.isAddGroupTransaction && partnerGroupList !== undefined
                    ? partnerGroupList
                    : [],
                isGroupDataChanges: isEditTransaction,
                currentValue: data.currentValue,
                transactionName: tagValues.label ? tagValues.label : '',
                notes: data.note,
              },
            })
          : mutateAddLedgerTransaction.mutate({
              input: {
                fundStructureId: props.fundStructureId,
                investorId: props.investorId,
                closeDate: new Date(data.closeDate).toISOString(),
                finalAmount: data.finalAmount,
                type: data.type,
                isPartnerGroupDataPresent: data.isAddGroupTransaction ? true : false,
                currentValue: data.currentValue,
                partnerGroupTransaction:
                  data.isAddGroupTransaction && partnerGroupList !== undefined
                    ? partnerGroupList
                    : [],
                transactionName: tagValues.label ? tagValues.label : '',
                notes: data.note,
              },
            })
        setLoading(false)
        props.handleClose()
      }, 1000)
    },
  })

  useEffect(() => {
    if (
      props.fundStructure.fundSize &&
      formik.values.finalAmount > 0 &&
      props.fundStructure.fundSize < formik.values.finalAmount
    ) {
      setTransactionAmountError(true)
    } else if (
      props.fundStructure.fundSize &&
      formik.values.finalAmount > 0 &&
      props.fundStructure.fundSize >= formik.values.finalAmount
    ) {
      setTransactionAmountError(false)
    }
  }, [props.fundStructure.fundSize, formik.values.finalAmount])

  const [type, setType] = useState<string>(
    formik.values.type === CapitalLedgerType.CapitalCall ? 'Capital Call' : 'Capital Distribution'
  )
  let maxDate = new Date().toISOString().split('T')[0]

  useEffect(() => {
    if (selectedFund.length > 0 && Number(formik.values.transactionAmount) > 0) {
      const nav = getNAV(Number(selectedFund[0].value))
      if (Number(formik.values.transactionAmount) % nav !== 0) {
        setUnitError(`Transaction Amount must be multiple of ${nav}`)
      } else if (availableAmount > 0 && Number(formik.values.transactionAmount) > availableAmount) {
        setUnitError(`Should be Lessthan/Equal to ${availableAmount}`)
      } else {
        setUnitError('')
      }
    }
  }, [formik.values.transactionAmount, selectedFund])

  useEffect(() => {
    if (formik.values.finalAmount !== amount && formik.values.isAddGroupTransaction) {
      setAmountError(true)
    } else setAmountError(false)
  }, [amount, formik.values.finalAmount])

  const partnerGroupTableHeader = () => {
    return (
      <tr className='fw-bold text-gray-600 '>
        <th className='fs-5 min-w-125px'>Group Name</th>
        <th className='fs-5 min-w-125px text-center'>Fund Unit</th>
        <th className='fs-5 min-w-100px text-center'>No. Of Unit</th>
        <th className='fs-5 min-w-100px text-center'>Invested Amount</th>
        <th className='fs-5 min-w-125px text-center'>Transaction Date</th>
      </tr>
    )
  }

  useEffect(() => {
    if (formik.values.closeDate !== '' && formik.values.transactionDate !== '') {
      if (new Date(formik.values.closeDate) < new Date(formik.values.transactionDate))
        setDateError(true)
      else {
        setDateError(false)
      }
    } else if (formik.values.closeDate === '' && formik.values.transactionDate !== '') {
      if (new Date() < new Date(formik.values.transactionDate)) setDateError(true)
      else {
        setDateError(false)
      }
    } else {
      setDateError(false)
    }
  }, [formik.values.closeDate, formik.values.transactionDate])

  function addGroupContribution() {
    const transactionObj = {
      groupName: formik.values.groupName,
      transactionAmount: formik.values.transactionAmount,
      transactionDate: formik.values.transactionDate
        ? new Date(formik.values.transactionDate).toISOString()
        : formik.values.transactionDate,
      groupId: Number(selectedPartner[0].value),
      fundUnitName: selectedFund[0].label,
      fundUnitId: Number(selectedFund[0].value),
      noOfUnits: Number(
        Number(formik.values.transactionAmount) / getNAV(Number(selectedFund[0].value))
      ),
    }
    const index =
      partnerGroupList &&
      partnerGroupList?.findIndex(
        (object: GroupTransaction) =>
          object.groupName === formik.values.groupName &&
          object.transactionAmount === Number(formik.values.transactionAmount) &&
          object.transactionDate === new Date(formik.values.transactionDate).toISOString()
      )

    if (index === -1 || index === undefined) {
      partnerGroupList
        ? setPartnerGroupList((prev: any) => [...prev, transactionObj])
        : setPartnerGroupList([transactionObj])
      // selectedPartnerNames
      //   ? setSelectedPartnerNames((prev: any) => [...prev, formik.values.groupName])
      //   : setSelectedPartnerNames([formik.values.groupName])
      setAmount((prev: number) => prev + Number(formik.values.transactionAmount))
      formik.setFieldValue('groupName', '')
      formik.setFieldValue('transactionAmount', '')
      formik.setFieldValue('transactionDate', '')
      setSelectedPartner([{ label: '--Select--', value: '' }])
    } else {
      Toast('Group entry already exist', ToastType.error)
    }

    updateSelectedGroupFunds(formik.values.groupName, formik.values.fundName)
  }

  function updateSelectedGroupFunds(groupName: string, fundName: string) {
    const selectedIndex =
      selectedGroupFunds &&
      selectedGroupFunds?.findIndex(
        (object: any) => object.groupName === groupName && object.fundName === fundName
      )
    const slecetdObject = { groupName: groupName, fundName: fundName }

    if (selectedIndex === -1) {
      selectedGroupFunds
        ? setSelectedGroupFunds((prev: any) => [...prev, slecetdObject])
        : setSelectedGroupFunds([slecetdObject])
    }
  }
  const contributionOptions = ['Delete Contribution', 'Edit Contribution']
  const actionMenu = (partnerGroup: GroupTransaction) => {
    let newOptions = contributionOptions
    return newOptions.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={(e) => {
            if (option === 'Delete Contribution') {
              deleteGroupContribution(partnerGroup)
            } else if (option === 'Edit Contribution') {
              editGroupContribution(partnerGroup)
            }
          }}
        >
          <span className='form-check-label fs-base fw-normal ms-9 cursor-pointer '>{option}</span>
        </label>
      )
    })
  }

  async function getUpdatedList(group: GroupTransaction) {
    const selectedIndex =
      (await partnerGroupList) &&
      partnerGroupList?.findIndex(
        (object: GroupTransaction) =>
          object.groupName === group.groupName &&
          object.transactionAmount === group.transactionAmount &&
          object.transactionDate === group.transactionDate
      )

    const updatedList = await partnerGroupList.filter(function (element: any, index: number) {
      return index !== selectedIndex
    })
    return updatedList
  }

  function updateFundUnitsDropdown(group: GroupTransaction) {
    const selectedIndex =
      selectedGroupFunds &&
      selectedGroupFunds?.findIndex(
        (object: any) =>
          object.groupName === group.groupName && object.fundName === group.fundUnitName
      )
    const updatedObjList = selectedGroupFunds.filter(function (object: any, index: number) {
      return index !== selectedIndex
    })
    setSelectedGroupFunds(updatedObjList)
  }

  async function editGroupContribution(group: GroupTransaction) {
    const updatedList = await getUpdatedList(group)
    setIsEditContribution(true)
    setAmount((prev: number) => prev - Number(group.transactionAmount))
    setPartnerGroupList([...updatedList])
    formik.setFieldValue('groupName', group.groupName)
    formik.setFieldValue('fundName', group.fundUnitName)
    formik.setFieldValue('transactionAmount', group.transactionAmount)
    formik.setFieldValue('transactionDate', group.transactionDate?.split('T')[0])
    setSelectedPartner([{ label: group.groupName, value: group.groupId }])
    setSelectedFund([{ label: group.fundUnitName, value: group.fundUnitId }])
    updateFundUnitsDropdown(group)
  }

  async function deleteGroupContribution(group: GroupTransaction) {
    const updatedList = await getUpdatedList(group)
    setAmount((prev: number) => prev - Number(group.transactionAmount))
    setPartnerGroupList([...updatedList])
    setIsEditTransaction(true)
    updateFundUnitsDropdown(group)
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='w-100 mx-auto'>
        <div className='row'>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label '>Transaction Name</label>
            <div className=''>
              {customTag === false ? (
                <Select
                  className='form-control-solid'
                  options={nameList}
                  placeholder={'--Select--'}
                  value={tagValues}
                  isMulti={false}
                  onChange={(e: any) => handleChange(e)}
                  styles={selectCustomStyles}
                />
              ) : (
                <div className='d-flex'>
                  <div className='me-4'>
                    <input
                      className='form-control form-control-solid me-2'
                      placeholder='Enter Transaction Name'
                      onChange={(e) => handleChangeValue(e)}
                    />
                    {errorMessage && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errorMessage}</div>
                      </div>
                    )}
                    {}
                  </div>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    type='submit'
                    disabled={disable}
                    onClick={(e) => {
                      setNameList((nameList) => [
                        ...nameList,
                        { value: inputText, label: inputText },
                      ])
                      setTagValues({ value: inputText, label: inputText })
                      setCustomTag(false)
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr012.svg'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    onClick={() => {
                      setCustomTag(false)
                      setErrorMessage('')
                      setTagValues(tagsObjList)
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Select Transaction Type</label>
            <Select
              options={transactionTypes}
              name='type'
              placeholder={type}
              onChange={(e: any) => (setType(e.label), formik.setFieldValue('type', e.value))}
              styles={selectCustomStyles}
            />

            <div className='text-danger mt-2'>
              {formik.touched.type && formik.errors.type && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.type}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-2'>
            <label className='form-label required'>
              Transaction Amount <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Transaction Amount'
              {...formik.getFieldProps('finalAmount')}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched && transactionAmountError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{`Amount should be less than  ${props.fundStructure.fundSize?.toLocaleString(
                    'en-IN'
                  )}`}</div>
                </div>
              )}
            </div>
            <div className='text-danger mt-2'>
              {formik.touched.finalAmount && formik.errors.finalAmount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.finalAmount}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Transaction Close Date</label>

            <input
              type='date'
              max={maxDate}
              className='form-control form-control-lg form-control-solid'
              {...formik.getFieldProps('closeDate')}
            />

            <div className='text-danger mt-2'>
              {formik.touched.closeDate && formik.errors.closeDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.closeDate}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>
              Current Fund Value <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Current Fund Value'
              {...formik.getFieldProps('currentValue')}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.currentValue && formik.errors.currentValue && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.currentValue}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 pe-4'>
            <label className='form-label '>Notes</label>
            <textarea
              className='form-control form-control-solid me-2'
              placeholder='Enter Note...'
              {...formik.getFieldProps('note')}
            />
          </div>

          <div className='row'>
            <div className='row align-items-baseline pb-4 pt-6'>
              <div className='col-9 col-md-9'>
                <div className='d-flex justify-content-between'>
                  <label className='form-label'>
                    Do You Want To Add Partner Group Contribution?
                  </label>
                  <div className='form-check form-switch form-check-custom form-check-solid me-10 align-items-baseline'>
                    <input
                      className='form-check-input h-20px w-40px'
                      type='checkbox'
                      checked={formik.values.isAddGroupTransaction}
                      {...formik.getFieldProps('isAddGroupTransaction')}
                      style={{
                        cursor: 'pointer',
                      }}
                      disabled={formik.values.finalAmount <= 0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {formik.values.isAddGroupTransaction && (
            <>
              <div className='row'>
                <div className='fv-row mb-6 col-12 col-sm-6 col-md-5'>
                  <label className='form-label required'>Select Partner Group</label>
                  <Select
                    options={partnerGroupsTypes}
                    name='groupName'
                    menuPlacement='auto'
                    placeholder={'--Select--'}
                    onChange={(e: any) => (
                      setSelectedPartner([e]), formik.setFieldValue('groupName', e.label)
                    )}
                    value={selectedPartner}
                    styles={selectCustomStyles}
                  />

                  <div className='text-danger mt-2'>
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.type}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='fv-row mb-6 col-12 col-sm-6 col-md-5'>
                  <label className='form-label required'>Select Fund Unit</label>
                  <Select
                    options={fundTypes}
                    name='fundName'
                    placeholder={'--Select--'}
                    menuPlacement='auto'
                    onChange={(e: any) => (
                      setSelectedFund([e]), formik.setFieldValue('fundName', e.label)
                    )}
                    value={selectedFund}
                    styles={selectCustomStyles}
                  />

                  <div className='text-danger mt-2'>
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.type}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='fv-row mb-6 col-12 col-sm-6 col-md-5'>
                  <label className='form-label required'>Transaction Amount</label>

                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Enter Amount'
                    {...formik.getFieldProps('transactionAmount')}
                  />
                  <div className='text-danger mt-2'>
                    <div className='fs-7'>
                      <div className='fv-help-block'>{unitError !== '' ? unitError : ''}</div>
                    </div>
                  </div>

                  <div className='text-danger mt-2'>
                    {formik.touched.transactionAmount && formik.errors.transactionAmount && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.transactionAmount}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='fv-row mb-6 col-12 col-sm-6 col-md-5'>
                  <label className='form-label required'>Transaction Date</label>

                  <input
                    type='date'
                    max={
                      (formik.values.closeDate &&
                        new Date(formik.values.closeDate)?.toISOString()?.split('T')[0]) ||
                      maxDate
                    }
                    className='form-control form-control-lg form-control-solid'
                    {...formik.getFieldProps('transactionDate')}
                  />

                  <div className='text-danger mt-2'>
                    {dateError && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {`Transaction Date should less than ${
                            formik.values.closeDate
                              ? isoToDate(formik.values.closeDate)
                              : isoToDate(maxDate)
                          }`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='fv-row mb-6 col-12 col-sm-6 col-md-2 pt-10'>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary '
                    disabled={
                      formik.values.transactionAmount === '' ||
                      formik.errors.transactionAmount !== undefined ||
                      formik.values.transactionDate === '' ||
                      formik.errors.transactionDate !== undefined ||
                      formik.values.groupName === '' ||
                      formik.values.groupName === '--Select--' ||
                      formik.values.fundName === '--Select--' ||
                      formik.values.fundName === '' ||
                      formik.errors.groupName !== undefined ||
                      dateError ||
                      unitError !== '' ||
                      Number(formik.values.transactionAmount) === 0
                    }
                    onClick={() => {
                      addGroupContribution()
                      setIsEditTransaction(true)
                      setIsEditContribution(false)
                    }}
                  >
                    Add
                  </button>
                </div>

                {partnerGroupList?.length > 0 && (
                  <div className='col-md-12 d-flex justify-content-left pt-2 pb-4'>
                    <div>
                      <div className='table-responsive'>
                        <div className='col card mh-350px border border-gray-300 p-4 overflow-auto'>
                          <table className='table align-middle gs-0 gy-4 table-row-dashed border-gray-200'>
                            <thead className='border-bottom'>{partnerGroupTableHeader()}</thead>
                            <tbody>
                              {partnerGroupList?.map(
                                (partnerGroup: GroupTransaction, index: number) => (
                                  <>
                                    {partnerGroup && (
                                      <tr key={index} className=''>
                                        <td className=''>
                                          <p className='fs-base mb-0 fw-bold'>
                                            {partnerGroup.groupName}
                                          </p>
                                        </td>
                                        <td className='fs-base mb-0 text-center'>
                                          <p className='fs-base mb-0 fw-bold'>
                                            {partnerGroup.fundUnitName}
                                          </p>
                                        </td>
                                        <td>
                                          <p
                                            className='fs-base mb-0 text-center'
                                            data-toggle='tooltip'
                                            data-placement='top'
                                            title={partnerGroup.noOfUnits?.toLocaleString('en-IN')}
                                          >
                                            {kFormatter(partnerGroup.noOfUnits)}
                                          </p>
                                        </td>
                                        <td>
                                          <p
                                            className='fs-base mb-0 text-center'
                                            data-toggle='tooltip'
                                            data-placement='top'
                                            title={Number(
                                              partnerGroup.transactionAmount
                                            ).toLocaleString('en-IN')}
                                          >
                                            {kFormatter(partnerGroup.transactionAmount)}
                                          </p>
                                        </td>
                                        <td>
                                          <p className='fs-base mb-0 text-center'>
                                            {isoToDate(partnerGroup.transactionDate)}
                                          </p>
                                        </td>
                                        <td>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                            data-kt-menu-flip='top-end'
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/general/gen052.svg'
                                              className='svg-icon-muted svg-icon-2x'
                                            />
                                          </button>
                                          <div
                                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
                                            data-kt-menu='true'
                                          >
                                            {actionMenu(partnerGroup)}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className='text-danger mt-2'>
                        {amountError && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{`Sum of Invested Amount ${amount.toLocaleString(
                              'en-IN'
                            )} must be equal to Transaction Amount ${formik.values.finalAmount.toLocaleString(
                              'en-IN'
                            )}`}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className='d-flex justify-content-between pt-15'>
            <button
              type='button'
              className='btn btn-lg btn-secondary me-3'
              onClick={props.handleClose}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={loading || amountError || transactionAmountError}
            >
              {!loading && (props.isEdit ? 'Edit Transaction' : 'Add Transaction')}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddLedgerTransactionForm
