import { useEffect, useState } from 'react'
import { MisDataType } from '../../../types/misData'
import Select from 'react-select'
import { selectCustomStyles } from '../../../utils/Select'
import ChartComponent, { ChartTypes } from './ChartComponent'
import {
  CompanyDataInput,
  CompanyObject,
  CompanyTypes,
  GetCompanyLatestReportQuery,
  GetCompanyReportByIdQuery,
  useGetCompanyLatestReportQuery,
  useGetCompanyReportByIdQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import EmptyReportsPage from './EmptyReportsPage'
import { ManageReportsPage } from './ManageReportsPage'
import { ReportsPageHeader } from './ReportsPageHeader'
import Toast, { ToastType } from '../../../utils/toast'

type FilterType = {
  label: string
  value: string
}

type Props = {
  companyData: CompanyObject
}

function ReportsPageWrapper({ companyData }: Props) {
  const { companyType, id, reportId } = useParams()

  const navigate = useNavigate()

  const compType = companyType as CompanyTypes
  let hissaCompanyId = ''
  let companyId = 0
  compType === CompanyTypes.Hissa ? (hissaCompanyId = id || '') : (companyId = Number(id) || 0)

  const input: CompanyDataInput = {
    type: compType,
    hissaCompanyId: hissaCompanyId || companyId.toString(),
    id: companyId,
  }

  const chartTypeOptions: FilterType[] = [
    { label: 'Bar Chart', value: 'bar' },
    { label: 'Line Chart', value: 'line' },
  ]

  if (!companyType || !id) {
    navigate('/error/404')
  }

  const {
    data: getLatestReportData,
    isLoading,
    error,
  } = useGetCompanyLatestReportQuery<GetCompanyLatestReportQuery, Error>(
    graphqlRequestClient,
    {
      input: input,
    },
    {
      enabled: reportId === null || reportId === undefined ? true : false,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  const { data: getReportData, isLoading: isReportDatLoading } = useGetCompanyReportByIdQuery<
    GetCompanyReportByIdQuery,
    Error
  >(
    graphqlRequestClient,
    {
      input: {
        companyType: compType,
        id: Number(reportId),
        companyId: Number(input.id),
        companyName: input.name,
        hissaCompanyId: input.hissaCompanyId,
      },
    },
    {
      enabled: reportId ? true : false,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  const reportData: MisDataType[] =
    (getLatestReportData?.getCompanyLatestReport as MisDataType[]) ||
    (getReportData?.getCompanyMisReportById as MisDataType[])

  const [selectedCompany, setSelectedCompany] = useState<FilterType>()
  const [chartType, setChartType] = useState<FilterType>({ label: 'Bar Chart', value: 'bar' })

  useEffect(() => {
    if (
      getReportData &&
      getReportData.getCompanyMisReportById &&
      getReportData.getCompanyMisReportById?.length > 0
    ) {
      const data = getReportData?.getCompanyMisReportById as MisDataType[]
      setSelectedCompany({ label: data[0].companyName, value: data[0].companyName })
    }
  }, [getReportData])

  useEffect(() => {
    if (
      getLatestReportData &&
      getLatestReportData.getCompanyLatestReport &&
      getLatestReportData.getCompanyLatestReport?.length > 0
    ) {
      const data = getLatestReportData?.getCompanyLatestReport as MisDataType[]
      setSelectedCompany({ label: data[0].companyName, value: data[0].companyName })
    }
  }, [getLatestReportData])

  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  if (isReportDatLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  if (error) {
    Toast(
      'Unable to parse the file. Try deleting the latest report if problem persists',
      ToastType.error
    )
    navigate(
      `/company/misreport-manage/${companyType}/${companyData.hissaCompanyId || companyData.id}`
    )
  }

  if (reportData && reportData.length === 0) {
    return <EmptyReportsPage companyData={companyData} />
  }

  return reportData && reportData.length > 0 ? (
    <>
      <div className='row mb-5'>
        <div className='col-12 d-flex flex-row justify-content-end'>
          <span>
            <Link
              className='px-4 rounded btn btn-sm border border-1 border-primary bg-primary h-38px fw-normal text-white'
              to={`/company/misreport-manage/${companyType}/${
                companyData.hissaCompanyId || companyData.id
              }`}
            >
              Manage Reports
            </Link>
          </span>
        </div>
      </div>
      <ReportsPageHeader data={reportData[0]} />
      <div>
        {/* <div className='d-flex flex-row pt-8 justify-content-start '>
          <span className='d-flex align-items-center fw-semibold fs-4'>Select Chart Type</span>
          <span>
            <Select
              className='justify-content-start w-150px mx-3'
              options={chartTypeOptions}
              onChange={(e: any) => setChartType(e)}
              value={chartType}
              styles={selectCustomStyles}
            />
          </span>
        </div> */}

        {reportData && reportData.length > 0 && (
          <div className='pt-8'>
            <ChartComponent
              data={reportData[0] as MisDataType}
              chartType={chartType.value as ChartTypes}
            />
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  )
}

export default ReportsPageWrapper
