import { AgGridReact } from 'ag-grid-react'
import { memo, useContext, useMemo, useRef, useState } from 'react'
import { agConfigSideBar, tableCellStyle } from '../../../../utils/AgGridConfig'
import { ColDef } from 'ag-grid-community'
import { AllTransactionsType, Shareholder } from '../../../../generated/graphql'
import {
  AmountInvestedRender,
  PercentageRender,
  ShareholderNameRender,
  ShareholderTypeRender,
  SharesRender,
} from './CompanyStage3TransactionAGComponents'
import { KTSVG } from '../../../../_metronic/helpers'
import { GlobalContext } from '../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'

type TransactionProps = {
  data: AllTransactionsType
}

export function CompanyStage3AGTable(props: TransactionProps) {
  const gridApi = useRef<any>(null)
  const shareholderData: Shareholder[] = props.data.companyData.shareholders as Shareholder[]

  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const componentsRegistry = useMemo(
    () => ({
      shareholder: memo(ShareholderNameRender),
      shareholderType: memo(ShareholderTypeRender),
      percentage: memo(PercentageRender),
      noOfShares: memo(SharesRender),
      amountInvested: memo(AmountInvestedRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <p className='fs-base text-muted text-center'>No Transactions found in this company.</p>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Shareholders',
        field: 'shareholder',
        cellRenderer: 'shareholder',
        cellStyle: tableCellStyle,
        filter: 'agTextColumnFilter',
        pinned: 'left',
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Shareholder Type',
        field: 'shareholderType',
        filter: 'agTextColumnFilter',
        cellRenderer: 'shareholderType',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Holding Percentage',
        field: 'percentage',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'percentage',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipPercentage',
      },
      {
        headerName: 'Number Of Shares',
        field: 'noOfShares',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'noOfShares',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipNoOfShares',
      },
      {
        headerName: `Amount Invested (${getSymbolOfCurrency(
          props.data.companyData.companyCurrency || 'INR - ₹'
        )})`,
        field: 'amountInvested',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'amountInvested',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipAmountInvested',
      },
    ],
    [userCurrency]
  )

  const rowData = useMemo(
    () =>
      shareholderData?.map((shareholder: Shareholder) => {
        return {
          shareholder: shareholder.name,
          shareholderType: shareholder.type,
          percentage: shareholder.percentage,
          tooltipPercentage: Math.abs(shareholder.percentage! * 100).toLocaleString(
            userCurrencyFormat
          ),
          amountInvested: shareholder.investedAmount,
          tooltipAmountInvested: Math.abs(shareholder.investedAmount!).toLocaleString(
            userCurrencyFormat
          ),
          noOfShares: shareholder.noOfShares,
          tooltipNoOfShares: Math.abs(shareholder.noOfShares!).toLocaleString(userCurrencyFormat),
          userCurrencyFormat: userCurrencyFormat,
          userCurrency: userCurrency,
        }
      }),
    [shareholderData, userCurrency]
  )

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode
    const filteredData = data.childrenAfterAggFilter.map((element: any) => element.data)

    const totalInvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested,
      0
    )

    const noOfShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.noOfShares,
      0
    )
    api.setPinnedBottomRowData([
      {
        shareholder: 'Total',
        shareholderType: null,
        percentage: null,
        tooltipPercentage: null,
        amountInvested: totalInvested,
        tooltipAmountInvested: Math.abs(totalInvested).toLocaleString(userCurrencyFormat),
        noOfShares: noOfShares,
        tooltipNoOfShares: Math.abs(noOfShares!).toLocaleString(userCurrencyFormat),
        userCurrencyFormat: userCurrencyFormat,
        userCurrency: userCurrency,
      },
    ])
  }

  return (
    <div
      className='card p-0 h-600px'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='m-0'>Captable</h1>
          {/* <span className='text-muted'>
            {transactionData.length > 0 ? `${transactionData.length} Transactions` : ''}
          </span> */}
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          onRowDataUpdated={setPinnedBottomRowData}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={true}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
