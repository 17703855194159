import { ToWords } from 'to-words'
export function numToWords(num: number, format: 'IN' | 'US'): string {
  const toWords = new ToWords({
    localeCode: `${format === 'IN' ? 'en-IN' : 'en-US'}`,
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      doNotAddOnly: false,
      ignoreZeroCurrency: false,
      currencyOptions: {
        name: `${format === 'IN' ? 'Rupee' : 'Dollar'}`,
        plural: `${format === 'IN' ? 'Rupees' : 'Dollars'}`,
        symbol: `${format === 'IN' ? '₹' : '$'}`,
        fractionalUnit: {
          name: `${format === 'IN' ? 'Paisa' : 'Cents'}`,
          plural: `${format === 'IN' ? 'Paise' : 'Cents'}`,
          symbol: '',
        },
      },
    },
  })
  if (toWords.isValidNumber(num)) {
    return toWords.convert(num)
  } else {
    return 'converting to words failed'
  }
}
