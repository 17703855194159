import {
  AllAccountOfUserQuery,
  useAddInvestorMutation,
  useAllAccountOfUserQuery,
  useGetAllInvestmentsQuery,
  useGetInvestorsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../utils/Select'
import { useFormik } from 'formik'
import Toast, { ToastType } from '../../../../utils/toast'
import { AddInvestorToAccountSchema } from '../AccountHelper'
import { useNavigate } from 'react-router'
import { useState } from 'react'

export enum Source {
  Existing = 'EXISTING',
  New = 'NEW',
}

export enum InvestorType {
  Angel = 'ANGEL',
  VC = 'VENTURE CAPITAL (VC)',
  Incubator = 'INCUBATOR',
  AngelFund = 'ANGEL FUND',
  AngelNetwork = 'ANGEL NETWORK',
  Corporate = 'CORPORATE',
  FamilyOffice = 'FAMILY OFFICE',
  PE = 'PRIVATE EQUITY (PE)',
  Accelerator = 'ACCELERATOR',
  FinancialInstitution = 'FINANCIAL INSTITUTION',
}

const initialValues = {
  accountName: '',
  source: '',
  fundname: '',
  fundType: '',
}

function AddInvestorToAccount() {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const mutateAddUser = useAddInvestorMutation<Error>(graphqlRequestClient, {}, {})
  const formik = useFormik({
    initialValues,
    validationSchema: AddInvestorToAccountSchema,
    onSubmit: async (values) => {
      setLoading(true)
      mutateAddUser.mutate(
        {
          input: {
            name: values.fundname.trim(),
            accountId: values.accountName,
            source: Source.New,
            type: values.fundType,
            globalInvestorId: values.accountName.toString(),
          },
        },
        {
          onSuccess: (result) => {
            setLoading(false)
            queryClient.invalidateQueries(useGetInvestorsQuery.getKey())
            queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
            navigate('/dashboard')
            Toast('Investor created Successfully', ToastType.success)
            navigate('/dashboard')
          },
          onError: (error: any) => {
            setLoading(false)
            Toast(`${error.response.errors[0].message}`, ToastType.error)
          },
        }
      )
    },
  })
  const { data } = useAllAccountOfUserQuery<AllAccountOfUserQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const investorTypes: string[] = [
    'ANGEL',
    'VENTURE CAPITAL (VC)',
    'INCUBATOR',
    'ANGEL FUND',
    'ANGEL NETWORK',
    'CORPORATE',
    'FAMILY OFFICE',
    'PRIVATE EQUITY (PE)',
    'ACCELERATOR',
    'FINANCIAL INSTITUTION',
  ]
  const investorTypesOptions = investorTypes.map((element) => {
    return { label: element, value: element }
  })
  const accountOptions = data?.getAllAccountOfUser?.map((account: any, index: number) => {
    return { label: account.name, value: account.id, index: index }
  })

  return (
    <div className='col d-flex justify-content-center'>
      <form className='card px-5 pt-5 w-50' onSubmit={formik.handleSubmit}>
        <div className='card-header px-0'>
          <h2 className='card-title fw-bold'>Add Investor/Fund</h2>
        </div>
        <div className='pb-5 pt-5'>
          <h3 className='required'>Select Account :</h3>
          <Select
            options={accountOptions}
            styles={selectCustomStyles}
            name='accountName'
            onChange={(e: any) => {
              formik.setFieldValue('accountName', e.value)
            }}
          />
          {formik.errors.accountName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.accountName}</div>
            </div>
          )}
        </div>

        <div className='pb-5'>
          <h3 className='required'>Investor/Fund Name :</h3>
          <input
            className='form-control form-control-lg'
            type='text'
            autoComplete='off'
            placeholder='Investor/Fund Name'
            {...formik.getFieldProps('fundname')}
          />
          {formik.errors.fundname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.fundname}</div>
            </div>
          )}
        </div>

        <div className='pb-5'>
          <h3 className='required'>Select Investor/Fund type :</h3>
          <Select
            options={investorTypesOptions}
            styles={selectCustomStyles}
            name='fundType'
            onChange={(e: any) => {
              formik.setFieldValue('fundType', e.value)
            }}
          />
          {formik.errors.fundType && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.fundType}</div>
            </div>
          )}
        </div>
        <div className='px-5 py-3 text-center'>
          <button className='btn btn-lg btn-primary' type='submit'>
            {!loading && <span className='indicator-label fw-bold'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddInvestorToAccount
