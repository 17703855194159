import {
  getUserId,
  getUserPreferences,
  setUserPreferences,
} from '../../../../utils/LocalStorageUtils'
import { CompanyType } from './classificationContextProvider'

export type StateType = {
  ctxInvestorIds: number[]
  ctxFundInvestorId: number[]
  userCurrency: string
  ctxGroupIds: number[]
  ctxFilterType: FilterType
  ctxUserId: number
  ctxLastVisited: string
  ctxCompanies: CompanyType[]
  includeExits: boolean
  showExits: boolean
}

export enum FilterType {
  fund = 'Fund',
  group = 'Group',
  all = 'All',
}

export enum Actions {
  setInvestorIds = 'SET_INV_IDS',
  setFundInvestorIds = 'SET_FUND_INV_IDS',
  setUserCurrency = 'SET_USER_CURRENCY',
  setGroupIds = 'SET_GRP_IDS',
  setFilterType = 'SET_FILTER_TYPE',
  setLastVisited = 'SET_LAST_VISITED',
  setCompanies = 'SET_COMPANIES',
  setIncludeExits = 'SET_INCLUDE_EXITS',
  setShowExits = 'SET_SHOW_EXITS',
}

export type ActionType =
  | { type: Actions.setFilterType; payload: FilterType }
  | { type: Actions.setGroupIds; payload: number[] }
  | { type: Actions.setFundInvestorIds; payload: number[] }
  | { type: Actions.setUserCurrency; payload: string }
  | { type: Actions.setInvestorIds; payload: number[] }
  | { type: Actions.setLastVisited; payload: string }
  | { type: Actions.setCompanies; payload: CompanyType[] }
  | { type: Actions.setIncludeExits; payload: boolean }
  | { type: Actions.setShowExits; payload: boolean }

export function getInitialState() {
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : undefined

  let initialState: StateType = {
    ctxInvestorIds: [],
    ctxFundInvestorId: [],
    userCurrency: '',
    ctxGroupIds: [],
    ctxFilterType: FilterType.fund,
    ctxUserId: 0,
    ctxLastVisited: '',
    ctxCompanies: [],
    includeExits: true,
    showExits: false,
  }

  if (userPreferencesObj) {
    initialState.ctxGroupIds = userPreferencesObj.selectedGroups
    initialState.ctxInvestorIds = userPreferencesObj.selectedInvestors
    initialState.ctxFundInvestorId = userPreferencesObj.selectedInvestor!
    initialState.userCurrency = userPreferencesObj.selectedCurrency!
    initialState.ctxFilterType = userPreferencesObj.filterType
  }
  return initialState
}

export function reducer(state: StateType, action: ActionType): StateType {
  const userId = getUserId()

  const updateUserPref = (
    name:
      | 'filterType'
      | 'selectedInvestors'
      | 'selectedGroups'
      | 'selectedInvestor'
      | 'selectedCurrency',
    value: any
  ) => {
    if (userId) {
      let userPreferencesObj = getUserPreferences(userId)
      if (userPreferencesObj) {
        if (name === 'filterType') {
          userPreferencesObj = { ...userPreferencesObj, filterType: value }
        } else if (name === 'selectedInvestors') {
          userPreferencesObj = { ...userPreferencesObj, selectedInvestors: value }
        } else if (name === 'selectedInvestor') {
          userPreferencesObj = { ...userPreferencesObj, selectedInvestor: value }
        } else if (name === 'selectedCurrency') {
          userPreferencesObj = { ...userPreferencesObj, selectedCurrency: value }
        } else if (name === 'selectedGroups') {
          userPreferencesObj = { ...userPreferencesObj, selectedGroups: value }
        }
        setUserPreferences(userPreferencesObj)
      }
    }
  }

  switch (action.type) {
    case Actions.setInvestorIds:
      updateUserPref('selectedInvestors', action.payload)
      return { ...state, ctxInvestorIds: action.payload }
    case Actions.setFundInvestorIds:
      updateUserPref('selectedInvestor', action.payload)
      return { ...state, ctxFundInvestorId: action.payload }
    case Actions.setUserCurrency:
      updateUserPref('selectedCurrency', action.payload)
      return { ...state, userCurrency: action.payload }
    case Actions.setGroupIds:
      updateUserPref('selectedGroups', action.payload)
      return { ...state, ctxGroupIds: action.payload }
    case Actions.setFilterType:
      updateUserPref('filterType', action.payload)
      return { ...state, ctxFilterType: action.payload }
    case Actions.setLastVisited:
      return { ...state, ctxLastVisited: action.payload }
    case Actions.setCompanies:
      return { ...state, ctxCompanies: action.payload }
    case Actions.setIncludeExits:
      return { ...state, includeExits: action.payload }
    case Actions.setShowExits:
      return { ...state, showExits: action.payload }
    default:
      return state
  }
}
