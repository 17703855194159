/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useAuth } from '../core/Auth'
import { LoginRequest } from '../../../../types/Login'
import { graphqlRequestClient } from '../../../../queries/client'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import jwtDecode from 'jwt-decode'
import { JwtPayload } from '../core/_models'
import {
  getUserPreferences,
  setUserInvestors,
  setUserPreferences,
  setUserRoles,
} from '../../../../utils/LocalStorageUtils'
import { UserPreferenceType } from '../../../../types/UserPreferences'
import { FilterType } from '../../../pages/store/context/reducer'
import { useLogin } from '../core/_requests'
import Toast, { ToastType } from '../../../../utils/toast'
import { useAuthorizationStore } from '../../../../store/useAuthStore'

const loginSchema = Yup.object().shape({
  emailId: Yup.string()
    .email('Wrong email format')
    .max(50, 'Maximum 50 symbols')
    .required('Please enter valid email'),
  password: Yup.string()
    .min(6, 'Password length should be atleast 6')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues: LoginRequest = {
  emailId: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const { saveAuth } = useAuth()
  const login = useLogin()
  const userAuthStore = useAuthorizationStore()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      login.mutate(
        {
          ...values,
        },
        {
          onSuccess: (result) => {
            const response = jwtDecode<JwtPayload>(result.data.data.token)
            saveAuth(result.data.data.token, result.data.defaultAccount)
            // setStringItem('iFrameToken', result.data.data.iFrameToken)
            graphqlRequestClient.setHeader('authorization', result.data.data.token)
            const userId = response.id!
            // check if userPref is already present in localStorage
            const userPref = getUserPreferences(userId)
            if (!userPref) {
              // add to localStorage if not present
              const newUserPreferences: UserPreferenceType = {
                id: userId,
                dashboardMetrics: [],
                portfolioMetrics: ['Net Profit', 'Current Value', 'Gain', 'MOIC', 'Industries'],
                selectedInvestors: [],
                selectedGroups: [],
                filterType: FilterType.fund,
              }
              setUserPreferences(newUserPreferences)
              userAuthStore.setUserPreferences(JSON.stringify(newUserPreferences))
            }
            setUserRoles('userRoles', result.data.actions)
            userAuthStore.setUserRoles(JSON.stringify(result.data.actions))
            userAuthStore.setUserInvestors(JSON.stringify(result.data.investorTypes))
            setUserInvestors('userInvestors', result.data.investorTypes)
            localStorage.setItem('userCompanies', JSON.stringify(result.data.userCompanies))
            Toast('Login Successful', ToastType.success)
          },
          onError: (error: any) => {
            saveAuth(undefined, false)
            setStatus(error?.response?.data?.errors)
            setSubmitting(false)
            setLoading(false)
            Toast(error?.response?.data?.errors, ToastType.error)
          },
        }
      )
    },
  })

  return (
    <div className='d-flex flex-column justify-content-around align-items-center h-100'>
      <div className='pb-20 p-lg-0'>
        <img
          src={toAbsoluteUrl('/media/logos/default.svg')}
          alt='Hissa_Logo'
          className='img-fluid h-60px'
        />
      </div>
      <div>
        <form
          className='form fv-plugins-bootstrap5 fv-plugins-framework w-300px w-md-400px w-lg-350px w-xl-400px p-5'
          onSubmit={formik.handleSubmit}
          id='kt_login_signin_form'
        >
          <div className='text-center'>
            <h1 className='fw-bold display-6'>Sign In</h1>
          </div>

          <div className='mb-3'>
            <label className='form-label fw-bold'>Email</label>
            <input
              className='form-control form-control-solid form-control-lg'
              type='email'
              autoComplete='off'
              placeholder='Email Address'
              {...formik.getFieldProps('emailId')}
            />
          </div>
          {formik.touched.emailId && formik.errors.emailId && (
            <div className='text-danger my-1'>
              <span role='alert'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-danger svg-icon-1 me-2'
                />
                {formik.errors.emailId}
              </span>
            </div>
          )}
          <div className='position-relative mb-3'>
            <label className='form-label fw-bold'>Password</label>
            <input
              className='form-control form-control-solid form-control-lg'
              placeholder='Password'
              type={`${show ? 'text' : 'password'}`}
              autoComplete='off'
              {...formik.getFieldProps('password')}
            />
            <span
              className='btn btn-icon position-absolute translate-middle-y end-0 top-50 mt-4'
              onClick={() => setShow(!show)}
            >
              {show ? <i className='bi bi-eye f-2'></i> : <i className='bi bi-eye-slash f-2'></i>}
            </span>
          </div>
          <div className='text-end'>
            <h6>
              <Link
                to='/auth/forgotPassword'
                className='link-primary fw-bold'
                style={{ marginLeft: '5px' }}
              >
                Forgot Password?
              </Link>
            </h6>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='text-danger my-1'>
              <span role='alert'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-danger svg-icon-1 me-2'
                />
                {formik.errors.password}
              </span>
            </div>
          )}
          {formik.status && (
            <div className='text-danger my-1'>
              <span role='alert'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-danger svg-icon-1 me-2'
                />
                {formik.status}
              </span>
            </div>
          )}
          <div className='text-center my-10'>
            <button className='btn btn-lg btn-primary' type='submit'>
              {!loading && <span className='indicator-label fw-bold'>Sign In</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {/* <h6 className='my-5'>
              Don't have an account ?{' '}
              <Link to='/auth/registration' className='fw-bold'>
                Sign Up
              </Link>
            </h6> */}
          </div>
        </form>
      </div>
      <div className='h-60'></div>
    </div>
  )
}
