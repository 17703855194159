import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../utils/Select'
import {
  AddFundStructureMutation,
  AddFundStructureMutationVariables,
  EditFundStructureMutation,
  EditFundStructureMutationVariables,
  FundStructure,
  useAddFundStructureMutation,
  useEditFundStructureMutation,
  useGetFundStructureQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import {
  IAddFundStructureData,
  addFundStructureInits,
  addFundStructureSchema,
} from './AddFundStructureHelper'

type Props = {
  investorId: number
  handleClose: VoidFunction
  isEdit: boolean
  data?: FundStructure
}

function AddFundStructureForm(props: Props) {
  const initialData: IAddFundStructureData = {
    carryPercentage: props.data?.carryPercentage?.toString() || '',
    fundExpenses: props.data?.fundExpenses?.toString() || '',
    fundSize: props.data?.fundSize?.toString() || '',
    fundTenure: props.data?.fundTenure?.toString() || '',
    hurdlePercentage: props.data?.hurdlePercentage?.toString() || '',
    inceptionDate: props.data?.inceptionDate.split('T')[0],
    investibleFundSize: props.data?.investibleFundSize || 100,
    fundType: props.data?.fundType || '',
  }
  const [data, setData] = useState<IAddFundStructureData>(
    _.cloneDeep(props.isEdit ? initialData : addFundStructureInits)
  )
  const [loading, setLoading] = useState(false)
  const fundTypes: string[] = [
    'VENTURE CAPITAL',
    'INCUBATOR',
    'ANGEL FUND',
    'ANGEL NETWORK',
    'CORPORATE',
    'PRIVATE EQUITY',
    'ACCELERATOR',
    'FINANCIAL INSTITUTION',
  ]
  const fundTypesOptions = fundTypes.map((element) => {
    return { label: element, value: element }
  })

  let maxDate = new Date().toISOString().split('T')[0]
  const mutateAddFundStructure = useAddFundStructureMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddFundStructureMutation,
        _variables: AddFundStructureMutationVariables,
        _context: unknown
      ) => {
        setData(_.cloneDeep(props.isEdit ? initialData : addFundStructureInits))
        Toast('Fund Structure Added Successfully!', ToastType.success)
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey({ input: props.investorId }))
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const mutateEditFundStructure = useEditFundStructureMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: EditFundStructureMutation,
        _variables: EditFundStructureMutationVariables,
        _context: unknown
      ) => {
        setData(_.cloneDeep(addFundStructureInits))
        Toast('Fund Structure Updated Successfully!', ToastType.success)
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey({ input: props.investorId }))
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const formik = useFormik<IAddFundStructureData>({
    initialValues: data,
    validationSchema: addFundStructureSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        props.isEdit
          ? mutateEditFundStructure.mutate({
              input: {
                fundStructureId: props.data?.id,
                fundSize: Number(data.fundSize),
                fundExpenses: Number(data.fundExpenses),
                fundTenure: Number(data.fundTenure),
                inceptionDate: new Date(data.inceptionDate).toISOString(),
                investibleFundSize: data.investibleFundSize,
                carryPercentage: Number(data.carryPercentage),
                hurdlePercentage: Number(data.hurdlePercentage),
                investorId: props.investorId,
                fundType: data.fundType,
              },
            })
          : mutateAddFundStructure.mutate({
              input: {
                fundSize: Number(data.fundSize),
                fundExpenses: Number(data.fundExpenses),
                fundTenure: Number(data.fundTenure),
                inceptionDate: new Date(data.inceptionDate).toISOString(),
                investibleFundSize: data.investibleFundSize,
                carryPercentage: Number(data.carryPercentage),
                hurdlePercentage: Number(data.hurdlePercentage),
                investorId: props.investorId,
                fundType: data.fundType,
              },
            })
        setLoading(false)
        props.handleClose()
      }, 1000)
    },
  })

  useEffect(() => {
    formik.setFieldValue(
      'investibleFundSize',
      Number(formik.values.fundSize) - Number(formik.values.fundExpenses)
    )
  }, [formik.values.fundSize, formik.values.fundExpenses])

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='w-100 mx-auto'>
        <div className='row'>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>
              Fund Size <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </label>

            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Fund Size'
              {...formik.getFieldProps('fundSize')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.fundSize && formik.errors.fundSize && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.fundSize}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>
              Fund Expenses <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Fund Expenses'
              {...formik.getFieldProps('fundExpenses')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.fundExpenses && formik.errors.fundExpenses && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.fundExpenses}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>
              Investible Fund Size <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Investible Fund Size'
              disabled={true}
              {...formik.getFieldProps('investibleFundSize')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.investibleFundSize && formik.errors.investibleFundSize && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.investibleFundSize}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Fund Tenure</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Fund Tenure'
              {...formik.getFieldProps('fundTenure')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.fundTenure && formik.errors.fundTenure && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.fundTenure}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Carry Percentage</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Carry Percentage'
              aria-placeholder=''
              {...formik.getFieldProps('carryPercentage')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.carryPercentage && formik.errors.carryPercentage && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.carryPercentage}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Hurdle Percentage</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Hurdle Percentage'
              {...formik.getFieldProps('hurdlePercentage')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.hurdlePercentage && formik.errors.hurdlePercentage && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.hurdlePercentage}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Inception Date</label>

            <input
              type='date'
              max={maxDate}
              className='form-control form-control-lg form-control-solid'
              {...formik.getFieldProps('inceptionDate')}
            />

            <div className='text-danger mt-2'>
              {formik.touched.inceptionDate && formik.errors.inceptionDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.inceptionDate}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label'>Fund Type</label>
            <Select
              options={fundTypesOptions}
              name='fundType'
              onChange={(e: any) => formik.setFieldValue('fundType', e.value)}
              placeholder={props.data?.fundType}
              styles={selectCustomStyles}
            />
            <div className='text-danger mt-2'>
              {formik.touched.fundType && formik.errors.fundType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.fundType}</div>
                </div>
              )}
            </div>
          </div>

          <div className='d-flex justify-content-between pt-15'>
            <button
              type='button'
              className='btn btn-lg btn-secondary me-3'
              onClick={props.handleClose}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && (props.isEdit ? 'Edit Fund Structure' : 'Add Fund Structure')}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddFundStructureForm
