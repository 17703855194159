import * as Yup from 'yup'

export interface DirectTransactionData {
  country: string
  currency: string
  investorId: number
  noOfShares: string
  companyName: string
  cin: string
  currentValuation: number
  sharePrice: number
  exchangeRate: string
  investmentDate: string
  currentSharePrice: number
  currentExchangeRate: number
  valuation: string
  roundDetails: string
  isAddShareNumber: boolean
  transactionCurrency: string
  industry: string
  shareDistinctiveRange: string
  securitySubType: string
}

const addDirectTransactionSchemas = Yup.object().shape({
  country: Yup.string().required('Select country').label('Country'),
  currency: Yup.string().required('Select currency').label('Currency'),
  companyName: Yup.string().required('Company Name').label('Company Name'),
  cin: Yup.string().required('CIN').label('CIN'),
  transactionCurrency: Yup.string()
    .required('Select Transaction Currency')
    .label('Transaction Currency'),
  investorId: Yup.number()
    .positive()
    .required('Select an investor or fund')
    .min(1, 'Select an investor or fund')
    .label('Investor ID'),
  noOfShares: Yup.number()
    .positive()
    .required()
    .moreThan(0, 'Should be greater than 0')
    .label('Number of shares'),
  sharePrice: Yup.number()
    .positive()
    .moreThan(0, 'Should be greater than 0')
    .required()
    .label('Share price'),
  exchangeRate: Yup.number().when(['currency', 'transactionCurrency'], {
    is: (transactionCurrency: string, currency: string) => transactionCurrency !== currency,
    then: Yup.number()
      .positive()
      .required()
      .moreThan(0, 'Should be greater than 0')
      .label('Exchange rate'),
    otherwise: Yup.number().notRequired(),
  }),
  investmentDate: Yup.string().required('Select investment date').label('Investment Date'),
  currentSharePrice: Yup.number()
    .positive()
    .moreThan(0, 'Should be greater than 0')
    .optional()
    .label('Current share price'),
  valuation: Yup.number()
    .positive()
    .required()
    .moreThan(0, 'Should be greater than 0')
    .label('Valuation'),
  currentValuation: Yup.number()
    .positive()
    .required()
    .moreThan(0, 'Should be greater than 0')
    .label('Current Valuation'),
  roundDetails: Yup.string()
    .required()
    .min(1, 'Round name cannot be less than 1 character')
    .label('Round details'),
  industry: Yup.string().optional().label('Industry'),

  // from: Yup.number().when(['noOfShares', 'totalShareNumber', 'isAddShareNumber'], {
  //   is: (noOfShares: number, totalShareNumber: number, isAddShareNumber:boolean) =>
  //   isAddShareNumber && noOfShares !== totalShareNumber,
  //   then: Yup.number().positive().required().moreThan(0).label('Starting Share Number'),
  //   otherwise: Yup.number().notRequired()}),

  // to: Yup.number().when(['noOfShares', 'totalShareNumber', 'isAddShareNumber'], {
  //   is: (noOfShares: number, totalShareNumber: number, isAddShareNumber:boolean) =>
  //   isAddShareNumber && noOfShares !== totalShareNumber,
  //   then: Yup.number().positive().required().moreThan(0).label('Ending Share Number'),
  //   otherwise: Yup.number().notRequired()}),

  totalShareNumber: Yup.number().max(Yup.ref('noOfShares')).label('Total Share Number'),
  securitySubType: Yup.string().required().label('Security Sub Type'),
})

export { addDirectTransactionSchemas }
