/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { AsideMenu } from './AsideMenu'
import { useCanUserAccess } from '../../../../app/pages/store/userRoleGrants'
import { KTSVG } from '../../../helpers'
import { AddAccountAccessModal } from '../../../../app/pages/accounts/components/AccountAccess/AddAccountAccessModal'

const AsideDefault: FC = () => {
  const { classes } = useLayout()
  const asideRef = useRef<HTMLDivElement | null>(null)
  const canCompanyAccess = useCanUserAccess('read', 'companyAccess')
  const [show, setShow] = useState<boolean>(true)
  const [showModal, setShowModal] = useState<boolean>(false)

  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const handleMouseEnter = () => {
      setIsHovered(true)
    }

    const handleMouseLeave = () => {
      setIsHovered(false)
    }

    const asideElement = asideRef.current
    if (asideElement) {
      asideElement.addEventListener('mouseenter', handleMouseEnter)
      asideElement.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        asideElement.removeEventListener('mouseenter', handleMouseEnter)
        asideElement.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [asideRef])

  useEffect(() => {
    if (isHovered) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [isHovered])

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='false'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      ref={asideRef}
    >
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid ' style={{ height: '60%' }}>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

      {showModal && (
        <AddAccountAccessModal showModal={showModal} handleClose={() => setShowModal(false)} />
      )}
      <div
        className='aside-footer pb-4'
        id='kt_aside_footer'
        style={{ height: '40%', display: show ? 'block' : 'none', zIndex: 50 }}
      >
        <div
          className='flex h-8 items-center justify-start menu-title'
          style={{ display: show ? 'block' : 'none', height: '275px' }}
        >
          {canCompanyAccess && (
            <div className='text-center px-4 pt-4'>
              {
                <>
                  <img
                    className='h-0 w-auto pb-4'
                    src={'/media/icons/duotune/general/access-image.svg'}
                    alt=''
                  />

                  <div>
                    <h4 className='text-white fw-bold'>{'Track Your Investments now!'}</h4>
                    <div className='text-white fs-8 pb-2'>
                      {'Pick the plan which best suits your needs'}
                    </div>
                  </div>
                </>
              }
              {
                <div
                  className='justify-content-end'
                  onClick={() => setShowModal(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <button className={'btn btn-primary'} type='button'>
                    Become a Pro
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      </div>
      <div className='aside-footer pb-4' id='kt_aside_footer'>
        {canCompanyAccess && (
          <div
            className='flex items-center justify-start menu-title'
            style={{ display: show ? 'none' : 'block' }}
          >
            {
              <div className='text-center'>
                {
                  <button className={'btn btn-primary px-4'} type='button'>
                    <KTSVG
                      path='/media/icons/duotune/general/lock.svg'
                      className='svg-icon-1 me-0 '
                    />
                  </button>
                }
              </div>
            }
          </div>
        )}
      </div>
    </div>
  )
}

export { AsideDefault }
