import { RateInterval, convertRate, xirr } from 'node-irr'
import { AllTransactionsType, Transaction_Type } from '../../../../generated/graphql'
import { HeaderCard } from './HeaderCard'
import { calculateXirr } from '../../../../utils/Utils'

type Props = {
  allTransactions: AllTransactionsType[]
}

export type CardType = {
  value: number
  name: string
  iconPath: string
  indicator: string
  isInteger: boolean
  className?: string
  info?: string
  isCurrency?: boolean
}

export function PortfolioHeaderCards(props: Props) {
  const aggregatedData = aggregateData(props.allTransactions)
  const cards = generateHeaderCards(aggregatedData)

  const cardsView: JSX.Element[] = []

  for (let i = 0; i < cards.length; i += 2) {
    cardsView.push(
      <div className='col'>
        <div className='d-flex flex-row gap-9'>
          <HeaderCard
            className={cards[i].className}
            value={cards[i].value}
            name={cards[i].name}
            info={cards[i].info}
            iconPath={cards[i].iconPath}
            indicator={cards[i].indicator}
            isInteger={cards[i].isInteger}
            isCurrency={cards[i].isCurrency}
            key={i}
          />
          <HeaderCard
            className={cards[i + 1].className}
            value={cards[i + 1].value}
            name={cards[i + 1].name}
            info={cards[i + 1].info}
            iconPath={cards[i + 1].iconPath}
            indicator={cards[i + 1].indicator}
            isInteger={cards[i + 1].isInteger}
            isCurrency={cards[i + 1].isCurrency}
            key={i + 1}
          />
        </div>
      </div>
    )
  }

  return <div className='row gap-3'>{cardsView}</div>
}

type AggregateDataType = {
  moic: number
  irr: number
  realizedValue: number
  noOfInvestments: number
  totalInvestment: number
  currentValue: number
  noOfHoldingCompanies: number
  totalNoOfCompanies: number
}

export function aggregateData(allTransactions: AllTransactionsType[]) {
  let numberOfInvestments = 0
  let currentValue = 0
  let realizedValue = 0
  let moic = 1
  let irr = 0
  let totalInvested = 0
  let holdingCompanies = 0
  const irrData: { amount: number; date: Date }[] = []

  allTransactions.forEach((company) => {
    const transactions = company.transactionData
    const investorData = company.investorData
    if (transactions && transactions.length > 0) {
      transactions.forEach((transaction) => {
        if (
          transaction?.transactionType === Transaction_Type.Buy ||
          transaction?.transactionType === Transaction_Type.SecondaryBuy
        ) {
          numberOfInvestments += 1
          totalInvested += transaction.amount
          irrData.push({
            amount: Math.abs(transaction.amount) * -1,
            date: new Date(transaction.transactionDate),
          })
        } else if (transaction?.transactionType === Transaction_Type.Sell) {
          irrData.push({
            amount: Math.abs(transaction.amount),
            date: new Date(transaction.transactionDate),
          })
          realizedValue += Math.abs(transaction.amount)
        }
      })
    }
    if (investorData && investorData.length > 0) {
      if (investorData.some((inv) => !inv?.exited)) holdingCompanies += 1
      investorData.forEach((inv) => {
        if (inv?.currentValue) currentValue += inv?.currentValue
      })
    }
  })
  if (irrData.length > 0) {
    irrData.push({ amount: currentValue, date: new Date() })
    if (isNaN(convertRate(xirr(irrData).rate, RateInterval.Year))) {
      const values = irrData.map((value: any) => value.amount)
      const dates = irrData.map((date: any) => new Date(date.date))
      const irrValue = calculateXirr(values, dates)
      irr = irrValue !== 'NA' ? irrValue * 100 : 0
    } else {
      irr = convertRate(xirr(irrData).rate, RateInterval.Year) * 100
    }
  }
  moic = totalInvested !== 0 ? (realizedValue + currentValue) / totalInvested : 1
  const aggregateObj: AggregateDataType = {
    moic: moic,
    irr: irr,
    realizedValue: realizedValue,
    noOfInvestments: numberOfInvestments,
    totalInvestment: totalInvested,
    currentValue: currentValue,
    noOfHoldingCompanies: holdingCompanies,
    totalNoOfCompanies: allTransactions.length,
  }
  return aggregateObj
}

function generateHeaderCards(aggregateData: AggregateDataType) {
  const cardData: CardType[] = [
    {
      className: 'col ',
      value: aggregateData.totalNoOfCompanies,
      name: 'No. of Companies',
      iconPath: 'media/icons/duotune/abstract/abs040.svg',
      indicator: '',
      isInteger: true,
    },
    {
      className: 'col',
      value: aggregateData.totalInvestment,
      name: 'Total Invested',
      iconPath: 'media/icons/duotune/abstract/abs034.svg',
      indicator: '',
      isCurrency: true,
      isInteger: false,
    },
    {
      className: 'col mb-5 mb-md-0',
      value: aggregateData.currentValue,
      name: 'Current Value',
      info: "Current holding value of an investment is the market value of the investment at a specific point in time. If some of the investments are in a different currency from the user-selected currency, today's exchange rate will be used for the calculations.",
      iconPath: 'media/icons/duotune/abstract/abs027.svg',
      indicator: '',
      isCurrency: true,
      isInteger: false,
    },
    {
      className: 'col mb-5 mb-md-0',
      value: aggregateData.realizedValue,
      name: 'Realized Value',
      info: 'Realized value on investment refers to the actual amount of money an investor has gained or lost from an investment after selling it or receiving dividends or interest payments.',
      iconPath: 'media/icons/duotune/abstract/abs048.svg',
      indicator: '',
      isCurrency: true,
      isInteger: false,
    },
    {
      className: 'col mb-5 mb-md-0',
      value: aggregateData.moic,
      name: 'MOIC',
      info: 'MOIC (Multiple on Invested Capital) is a metric that measures the total value of an investment, including all returns, divided by the initial investment amount.',
      iconPath: 'media/icons/duotune/communication/com001.svg',
      indicator: 'X',
      isInteger: false,
    },
    {
      className: 'col mb-5 mb-md-0',
      value: aggregateData.irr,
      name: 'IRR',
      info: 'IRR (Internal Rate of Return) is a way to calculate the annualized return of an investment that has irregular cash flows over time, such as investments with varying amounts of money added or withdrawn at different times.',
      iconPath: 'media/icons/duotune/graphs/gra004.svg',
      indicator: '%',
      isInteger: false,
    },
  ]
  return cardData
}
