import { useEffect, useRef, useState } from 'react'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { Formik, Form, FormikErrors, FormikHelpers } from 'formik'

import {
  DocxToPdfMutation,
  DocxToPdfMutationVariables,
  GetGlobalCompaniesQuery,
  GetInvestorsQuery,
  GetS3UrlForTermsheetQuery,
  GetUrlForS3ObjectQuery,
  GlobalCompanySearchType,
  InsertSignatureMutation,
  InsertSignatureMutationVariables,
  Investor,
  SendNewTermsheetMutation,
  SendNewTermsheetMutationVariables,
  useDocxToPdfMutation,
  useGetDealTemplatesQuery,
  useGetGlobalCompaniesQuery,
  useGetInvestorsQuery,
  useGetS3UrlForTermsheetQuery,
  useGetTermsheetsQuery,
  useGetUrlForS3ObjectQuery,
  useInsertSignatureMutation,
  useSendNewTermsheetMutation,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import jwtDecode from 'jwt-decode'
import { getAuth, JwtPayload } from '../../../../modules/auth'
import { formatDate } from '../../../../../utils/FormatDate'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import axios from 'axios'
import Toast, { ToastType } from '../../../../../utils/toast'
import { FieldsType, GroupsType, StepperType } from '../../../../../utils/stepper'
import * as Yup from 'yup'
import {
  GetCompanies,
  GenerateTermsheet,
  TermSheetName,
  GetInvestors,
  DraftNotes,
  RecommendedFields,
} from './generateTermsheet'
import { convertBase64ToBlob, downloadBlobObject } from '../../../../../utils/ExportExcel'
import EmailTermsheet from '../emailTermsheet/EmailTermsheet'
import LazyPdfDocument from '../../../../../utils/LazyPdfDocument'
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from 'react-signature-canvas'
import convertToBase64 from '../../../../../utils/convertTobase64'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../utils/Select'
import { MultiSelectOptionType } from '../../../../../utils/MultiSelect'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import {
  DocumentEditorContainerComponent,
  ImageFormat,
  Inject,
  Toolbar,
  CustomToolbarItemModel,
  ToolbarItem,
} from '@syncfusion/ej2-react-documenteditor'
import {
  PdfDocument,
  PdfSection,
  PdfPageSettings,
  PdfPageOrientation,
  SizeF,
  PdfBitmap,
} from '@syncfusion/ej2-pdf-export'
import { getInits1 } from './DraftTermsheetView'
import { HissaAiModal } from '../../../deals/components/template-builder/HissaAi/HissaAiModal'
import { useChatbotStore } from '../../../../../store/termsheetChatbot'
import { getNameForKey } from '../../../../../utils/TermsheetUtils'
import { ImportInvestmentModel } from './ImportInvestmentModel'
import _ from 'lodash'

type CompanyDetail = {
  investorList: any[]
  name?: string
  companyId?: string
}

type Props = {
  allowedSections?: string[]
  wizardRef?: any
  templateId?: number
  initialValues?: any
  draftTermsheetId?: number
  genericData?: any
}

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}
export async function docToPdf(
  editorData: DocumentEditorContainerComponent | any
): Promise<Blob | null> {
  return new Promise(async (resolve) => {
    const pdfdocument: PdfDocument = new PdfDocument()
    const count: number = editorData.current?.documentEditor.pageCount
    editorData.current!.documentEditor.documentEditorSettings.printDevicePixelRatio = 2
    let loadedPage = 0
    for (let i = 1; i <= count; i++) {
      const format: ImageFormat = 'image/jpeg' as ImageFormat
      // Getting pages as image
      const image = editorData.current?.documentEditor.exportAsImage(i, format)
      image.onload = async function () {
        const imageHeight = parseInt(image.style.height.toString().replace('px', ''))
        const imageWidth = parseInt(image.style.width.toString().replace('px', ''))
        const section: PdfSection = pdfdocument.sections.add() as PdfSection
        const settings: PdfPageSettings = new PdfPageSettings(0)
        if (imageWidth > imageHeight) {
          settings.orientation = PdfPageOrientation.Landscape
        }
        settings.size = new SizeF(imageWidth, imageHeight)
        ;(section as PdfSection).setPageSettings(settings)
        const page = section.pages.add()
        const graphics = page.graphics
        const imageStr = image.src.replace('data:image/jpeg;base64,', '')
        const pdfImage = new PdfBitmap(imageStr)
        graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight)
        loadedPage++
        if (loadedPage == count) {
          // Exporting the document as pdf
          const blobData = await pdfdocument.save().then(async (p: { blobData: Blob }) => {
            return p.blobData
          })
          resolve(blobData)
        }
      }
    }
  })
}

const SendTermsheetWizard = ({
  allowedSections,
  wizardRef,
  templateId,
  initialValues,
  draftTermsheetId,
  genericData,
}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [termsheetId, setTermsheetId] = useState<any>()
  const [allCompanies, setAllCompanies] = useState<GlobalCompanySearchType[]>([])
  const [recFields, setRecommendedFields] = useState<any>([])

  const navigate = useNavigate()
  useEffect(() => {
    const unloadCallback = (event: { preventDefault: () => void; returnValue: string }) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }
    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [])
  const { data: globalCompanies } = useGetGlobalCompaniesQuery<GetGlobalCompaniesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  useEffect(() => {
    if (globalCompanies && globalCompanies.globalCompaniesSearch) {
      setAllCompanies(
        _.cloneDeep(globalCompanies.globalCompaniesSearch) as GlobalCompanySearchType[]
      )
    }
  }, [globalCompanies])

  useEffect(() => {
    setTimeout(() => {
      !initialValues && setShowFreeTextModal(true)
    }, 1000)
  }, [])

  const location = useLocation()
  const name = (location.state as CompanyDetail)?.name
  const companyId = (location.state as CompanyDetail)?.companyId
  const [companyObj, setCompanyObj] = useState<any>()
  const submitBtnRef = useRef<HTMLButtonElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<any>(
    'UEsDBBQAAAAIAJtRQ1fyWXzmlgEAAOsDAAAEAAAAc2ZkdMRSy07DMBD8FbRcoypuSwu5IVBPqELiCBzsxE4s5SXHFdAq/86sHSKoxBHhQzK7tndnZn2irve2sUf9ZApPmXcHndCgc8qeT6TCNzeUncgMR8qEWKQJGSToTtZWOUsIcZgu02lxwij784SRZ7HRZ4n2LJMPPWXpIh0T6kP/2rYhkZCbkZmRGiYgAURANaOFWN/w2m7E1bVYLzkNmdj2oMTVbdDoa7Sjh04Wti0XcTGLf5U+vo6vCVXMYQxD6R3TqQrKVpvAZkb9G2UbsQywomx7E2DdAAbkZuRnpGZUGO6If6cjYBF8q2d/UXKIf92CCzwE5bhvv/46nitbmE63TiqbQ4lie/b67VGWmiAHmZIVBNd20yLW5j/iGEIZNKN95xpZo0b7/j2KA7HQLdLVahyTcEfEO/fayEPtLx6lk6WTfXWx61pP3LfwMGgyrZkeAFgw5xgFaWxB5Zs6IOkd7A4z55fEqrk3MIh+fzUvB8x+hTbcKP9lf/21jyLTPmJI139SNe8aHMKo4KxXECTGTwAAAP//AwBQSwECLQAUAAAACACbUUNX8ll85pYBAADrAwAABAAAAAAAAAAAACAAAAAAAAAAc2ZkdFBLBQYAAAAAAQABADIAAAC4AQAAAAA='
  )
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  const [isEdit, setIsEdit] = useState(false)
  const [templateName, setTemplateName] = useState<string>('My Document')
  const [companyName, setCompanyName] = useState('')
  const [formikValues, setFormikValues] = useState<any>('')
  const [fieldValue, setFieldValue] = useState<any>('')
  const [sfdt, setSfdt] = useState<any>(
    'UEsDBBQAAAAIAJtRQ1fyWXzmlgEAAOsDAAAEAAAAc2ZkdMRSy07DMBD8FbRcoypuSwu5IVBPqELiCBzsxE4s5SXHFdAq/86sHSKoxBHhQzK7tndnZn2irve2sUf9ZApPmXcHndCgc8qeT6TCNzeUncgMR8qEWKQJGSToTtZWOUsIcZgu02lxwij784SRZ7HRZ4n2LJMPPWXpIh0T6kP/2rYhkZCbkZmRGiYgAURANaOFWN/w2m7E1bVYLzkNmdj2oMTVbdDoa7Sjh04Wti0XcTGLf5U+vo6vCVXMYQxD6R3TqQrKVpvAZkb9G2UbsQywomx7E2DdAAbkZuRnpGZUGO6If6cjYBF8q2d/UXKIf92CCzwE5bhvv/46nitbmE63TiqbQ4lie/b67VGWmiAHmZIVBNd20yLW5j/iGEIZNKN95xpZo0b7/j2KA7HQLdLVahyTcEfEO/fayEPtLx6lk6WTfXWx61pP3LfwMGgyrZkeAFgw5xgFaWxB5Zs6IOkd7A4z55fEqrk3MIh+fzUvB8x+hTbcKP9lf/21jyLTPmJI139SNe8aHMKo4KxXECTGTwAAAP//AwBQSwECLQAUAAAACACbUUNX8ll85pYBAADrAwAABAAAAAAAAAAAACAAAAAAAAAAc2ZkdFBLBQYAAAAAAQABADIAAAC4AQAAAAA='
  )
  const [queryEnabled, setQueryEnabled] = useState<boolean>(false)
  const [hissaCompanyId, setHissaCompanyId] = useState<string>('')
  const [investorId, setInvestorId] = useState<number>(-1)
  const [showModal, setShowModal] = useState(false)
  const [wordFile, setWordFile] = useState<any>()
  const [showSign, setShowSign] = useState<boolean>(false)
  const [signs, setSigns] = useState<ReactSignatureCanvas | null | any>()
  const [newPdf, setNewPdf] = useState<any>(' ')
  const [final, setFinal] = useState<any>()
  const [upload, setUpload] = useState<Blob>()
  const [disable, setDisable] = useState<boolean>(false)
  const [imageInput, setImageInput] = useState(false)
  const [signInput, setSignInput] = useState(false)
  const [submitLoding, setSubmitLoading] = useState(false)
  const [docType, setDocType] = useState('')
  const signOptions: MultiSelectOptionType[] = [
    { value: 1, label: 'Image' },
    { value: 2, label: 'Signature' },
  ]

  const useChatBot = useChatbotStore()
  const [currentStepIndex, setCurrentStepIndex] = useState<number | undefined>()
  const [showFreeTextModal, setShowFreeTextModal] = useState<boolean>(useChatBot.isOpen || false)
  const [aiInits, setAiInits] = useState<any>()

  useEffect(() => {
    setCurrentStepIndex(stepper.current?.currentStepIndex)
  }, [stepper.current?.currentStepIndex])

  useEffect(() => {
    setShowFreeTextModal(useChatBot.isOpen)
  }, [useChatBot.isOpen])

  const handleFreeTextModalClose = () => {
    setShowFreeTextModal(false)
    useChatBot.setIsOpen(false)
  }

  const templateData = genericData || (location.state as any)?.genericData
  const templateDetails: any[] = JSON.parse(templateData.jsonData)[0].templateDetails
    ? JSON.parse(templateData.jsonData)[0].templateDetails
    : []
  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        key: templateData?.thumbnailURL,
        bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
      },
    },
    {
      enabled:
        templateData?.thumbnailURL !== undefined &&
        templateData?.thumbnailURL !== null &&
        templateData?.thumbnailURL !== '',
    }
  )

  useEffect(() => {
    if (draftTermsheetId) setTermsheetId(draftTermsheetId)
  }, [draftTermsheetId])

  const { data } = useGetInvestorsQuery<GetInvestorsQuery, Error>(graphqlRequestClient, {}, {})

  const investorList = data?.investors.map((investor: Investor) => {
    return {
      investorId: investor.id,
      investorName: investor.name,
    }
  })

  const usePreviewTermsheetMutation = useMutation(
    ['previewTermsheet'],
    (object: any) => axios.post(`${process.env.REACT_APP_API_URL}/termsheetPreview`, object),
    {
      onSuccess: async (data) => {
        let base64File: string = data.data?.base64
        if (!base64File.startsWith('data:application/pdf;base64,'))
          base64File = `data:application/pdf;base64,${base64File}`
        setPreviewUrl(base64File)
        setWordFile(data.data?.wordFile)
        const blob = await convertBase64ToBlob(
          data.data?.wordFile,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        )
        const blobPdf = await convertBase64ToBlob(data.data?.base64, 'application/pdf')
        setFinal(blobPdf)
        //setUpload(blob)
        // onFileChange(data.data?.base64)
        onFileChange(data.data?.wordFile)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    }
  )
  const addSignatureMutation = useInsertSignatureMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: async (
        data: InsertSignatureMutation,
        _variables: InsertSignatureMutationVariables,
        _context: unknown
      ) => {
        setSubmitLoading(false)
        let base64File: any = data.addSignature
        if (!base64File.startsWith('data:application/pdf;base64,'))
          base64File = `data:application/pdf;base64,${base64File}`
        const blob = await convertBase64ToBlob(data.addSignature!, 'application/pdf')
        setFinal(blob)
        setNewPdf(base64File)
        setLoading(false)
        setDisable(false)
      },
      onError: (err: any) => {
        setSubmitLoading(false)
        setLoading(false)
      },
    },
    {}
  )
  const fileConverterMutation = useDocxToPdfMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DocxToPdfMutation,
        _variables: DocxToPdfMutationVariables,
        _context: unknown
      ) => {
        let base64File: any = data.docxToPdfConverter
        if (!base64File.startsWith('data:application/pdf;base64,'))
          base64File = `data:application/pdf;base64,${base64File}`
        console.log(data)
        setNewPdf(base64File)
      },
      onError: (err: any) => {
        console.log(err)
      },
    },
    {}
  )
  const handleDownload = async () => {
    const bufferr: any = wordFile || ''
    const blob = await convertBase64ToBlob(bufferr, 'docx')
    await downloadBlobObject(blob, `${templateData.name}.docx`)
  }
  const handleUpload = async (file: any) => {
    setUpload(file)
    fileConverterMutation.mutate({ input: { fileName: file } })
  }

  const mutateSendTermsheet = useSendNewTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendNewTermsheetMutation,
        _variables: SendNewTermsheetMutationVariables,
        _context: unknown
      ) => {
        setLoading(false)
        console.log(data)
        setTermsheetId(Number(data.addTermsheet.saved))
        setShowModal(true)
        submitBtnRef.current?.removeAttribute('data-kt-indicator')
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        queryClient.invalidateQueries(useGetDealTemplatesQuery.getKey())
        if (!termsheetId) setTermsheetId(data.addTermsheet.id!)
        Toast('Termsheet Sent Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        setLoading(false)
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const mutateSendDraftTermsheet = useSendNewTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendNewTermsheetMutation,
        _variables: SendNewTermsheetMutationVariables,
        _context: unknown
      ) => {
        setLoading(false)
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        if (!termsheetId) setTermsheetId(data.addTermsheet.termsheetId!)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const getTermsheetUrl = useGetS3UrlForTermsheetQuery<GetS3UrlForTermsheetQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        companyId: hissaCompanyId,
        accountId: Number(templateData.accountId),
      },
    },
    {
      enabled: queryEnabled,
      onSuccess: () => {
        setQueryEnabled(false)
      },
    }
  )
  const handleImage = async (file: File) => {
    let base64: any = await convertToBase64(file)
    if (!base64.startsWith('data:image/png;base64,')) base64 = `data:image/png;base64,${base64}`
    setSigns(base64)
  }
  const NewInstance = axios.create({
    // Configuration
    baseURL: '',
    timeout: 8000,
    headers: {},
  })

  let container = useRef<DocumentEditorContainerComponent | null>(null)
  let toolItem: CustomToolbarItemModel = {
    prefixIcon: 'e-save icon',
    tooltipText: 'Download the Document',
    text: 'Download',
    id: 'Download',
  }
  let items: (CustomToolbarItemModel | ToolbarItem)[] = [
    'Redo',
    'Undo',
    'Find',
    'RestrictEditing',
    toolItem,
  ]
  const handleToolbarClick = (e: any) => {
    if (e.item.id === 'Download') {
      container?.current!.documentEditor.save(templateName, 'Docx')
    }
  }
  async function handlesign() {
    setSubmitLoading(true)
    addSignatureMutation.mutate({
      input: {
        sign: imageInput ? signs : signs?.getTrimmedCanvas().toDataURL('image/png') || ' ',
        fileName: upload,
      },
    })
  }

  const onLoadDefault = () => {
    const defaultDocument = {
      sfdt: sfdt,
    }
    container.current?.documentEditor.open(JSON.stringify(defaultDocument))
  }
  useEffect(() => {
    onLoadDefault()
  }, [sfdt])

  const onFileChange = async (e: any) => {
    const ab = await convertBase64ToBlob(e, 'docx')
    let formData = new FormData()
    formData.append('files', ab)
    const fetchOptions = {
      method: 'POST',
      body: formData,
    }
    await fetch('https://syncfusion-api.hissa.com/api/documenteditor/Import', fetchOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          return response.json()
        } else {
          throw new Error('Request failed with status: ' + response.status)
        }
      })
      .then((data) => {
        setSfdt(data.sfdt)
      })
      .catch((error) => {
        console.error('Fetch error:', error)
      })
    onLoadDefault()
  }
  const handleSendTermsheet = (termsheet: any) => {
    submitBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    mutateSendTermsheet.mutate({ input: termsheet, sendImmediately: true })
  }
  const handleSendDraftTermsheet = (termsheet: any) => {
    mutateSendDraftTermsheet &&
      mutateSendDraftTermsheet?.mutate({ input: termsheet, sendImmediately: false })
  }

  const getTermsheetData = (values: TermsheetType) => {
    const termsheetData: any = {
      investorId: parseInt(values?.investorId === null ? '0' : values?.investorId.toString()),
      investorName: values?.investorName,
      templateId: Number(templateData.id),
      termsheetName: values?.termsheetName,
      companyName: name || (companyObj && companyObj.companyName) || initialValues?.companyName,
      cin: companyId || values?.cin || (companyObj && companyObj.companyId) || initialValues?.cin,
      roundSize:
        values?.roundSize !== undefined ? Math.round(Number(values?.roundSize)).toString() : 0,
      valuation:
        values.isPriced !== undefined
          ? values?.isPriced === 'No'
            ? Math.round(Number(values?.floorValuation)).toString() || 0
            : Math.round(Number(values?.valuation)).toString() || 0
          : values?.valuation !== undefined
          ? Math.round(Number(values?.valuation)).toString()
          : 0,
      expiryDate:
        values?.expiryDate && values?.expiryDate !== ''
          ? formatDate(values.expiryDate, 'dd/MM/yyyy')
          : '',
      investmentAmount:
        values?.investmentAmount !== undefined ? Math.round(values?.investmentAmount) : 0,
      additionalSections: {},
      sections: {},
      companyId:
        values.companyId ||
        (companyObj && companyObj.hissaCompanyId) ||
        initialValues?.hissaCompanyId,
      type: docType,
    }

    jsonData.forEach((section: StepperType) => {
      if (Number(section.sequenceNumber) > 0) {
        if (section.stepname !== 'Details') {
          const stepName = camelize(section.stepname)
          if (!Object.keys(termsheetData.sections).includes(stepName)) {
            termsheetData.sections[stepName] = {}
          }

          section.groups.forEach((group: GroupsType) => {
            group.fields.forEach((field: FieldsType) => {
              if (section.condition !== undefined) {
                if (values[section.condition.name] === section.condition.value) {
                  if (field.condition !== undefined) {
                    if (String(values[field.condition.name]) === String(field.condition.value))
                      termsheetData.sections[stepName][field.key] = values[field.key]
                  } else {
                    termsheetData.sections[stepName][field.key] = values[field.key]
                  }
                }
              } else {
                if (section.sequenceNumber == 1) {
                  mandatoryFields.forEach(
                    (element: string) =>
                      (termsheetData.sections[stepName][element] = values[element])
                  )
                }
                if (field.condition !== undefined) {
                  if (String(values[field.condition.name]) === String(field.condition.value))
                    termsheetData.sections[stepName][field.key] = values[field.key]
                } else {
                  termsheetData.sections[stepName][field.key] = values[field.key]
                }
              }
            })
          })
        }
      }
    })

    return termsheetData
  }

  const jsonData = JSON.parse(templateData.jsonData)
  useEffect(() => {
    setDocType(jsonData[0].type)
  }, [jsonData])

  const stepperValues: string[] = []
  const initialObj: any = {}
  const errorObj: any = {}
  const detailedObj: any = {}
  jsonData.forEach((sections: StepperType) => {
    if (Number(sections.sequenceNumber) > 0) {
      if (sections.stepname !== undefined) {
        errorObj[camelize(sections.stepname)] = 'boolean'
      }
      sections.groups.forEach((group: GroupsType) =>
        group.fields.forEach((field: FieldsType) => {
          if (sections.condition && formikValues === sections.condition?.value) {
            if (field.key !== undefined) initialObj[field.key] = field.value
          } else if (!sections.condition) {
            if (field.key !== undefined) initialObj[field.key] = field.value
          }
        })
      )
    }
  })

  type MapSchemaTypes = {
    string: string
    boolean: boolean
    number: number
    stringArray: string[]
    objectArray: any[]
  }
  type MapSchema<T extends Record<string, keyof MapSchemaTypes>> = {
    -readonly [K in keyof T]: MapSchemaTypes[T[K]]
  }
  function asSchema<T extends Record<string, keyof MapSchemaTypes>>(template: T): T {
    return template
  }

  const personSchema = asSchema(initialObj)
  const errorSchema = asSchema(errorObj)
  type TermsheetType = MapSchema<typeof personSchema>
  type StepperErrors = MapSchema<typeof errorSchema>

  const steppers: any = {}
  const init: TermsheetType = {}
  const initialStepperErrors: StepperErrors = {}
  const allFields: any[] = []
  const recommendedFields: any[] = []
  const validationSchema: any[] = []
  const mandatoryFields = [
    'termsheetName',
    'investorName',
    'investorId',
    'companyName',
    'companyId',
  ]
  const [stepperErrors, setStepperErrors] = useState<StepperErrors>(initialStepperErrors)

  Object.keys(errorObj).forEach((element: string) => {
    initialStepperErrors[element] = false
  })

  function isNumeric(n: any) {
    return !isNaN(n)
  }

  jsonData.forEach((sections: StepperType) => {
    const groupObj: any = {}
    const fieldList: string[] = []
    if (Number(sections.sequenceNumber) > 0) {
      allFields.push({
        stepName: sections.stepname,
        step: sections.sequenceNumber,
        fields: [...sections.groups],
      })
      sections.groups.forEach((group: GroupsType) => {
        group.fields.forEach((field: FieldsType) => {
          if (sections.condition !== undefined) {
            const conditionValue = sections.condition && sections.condition.value
            if ((formikValues !== '' && formikValues === conditionValue) || formikValues === '') {
              fieldList.push(field.key)
              if (field.key !== undefined) {
                if (field.value === 'boolean') {
                  init[field.key] = false
                  groupObj[field.key] = Yup.boolean().notRequired()
                }

                if (field.value === 'string' && field.type === 'radio') {
                  init[field.key] = field.values && field.values[0]
                  field.required === 'true'
                    ? (groupObj[field.key] = Yup.string().required().label(field.name))
                    : (groupObj[field.key] = Yup.string().notRequired())
                } else if (field.value === 'string') {
                  init[field.key] = ''
                  const name = field.condition && field.condition.name
                  const value = field.condition && field.condition.value
                  const actualValue = init[name]
                  field.required === 'true' && field.condition
                    ? (groupObj[field.key] = Yup.string().when(name, {
                        is: String(actualValue) !== String(value),
                        then: Yup.string().required().label(field.name),
                        otherwise: Yup.string().notRequired(),
                      }))
                    : field.required === 'true'
                    ? (groupObj[field.key] = Yup.string().required().label(field.name))
                    : (groupObj[field.key] = Yup.string().notRequired())
                }

                if (field.value === 'number') {
                  if (field.value === 'number' && field.type === 'numberINR') {
                    init[field.key] = '0.00'
                    const name = field.condition && field.condition.name
                    const value = field.condition && field.condition.value
                    const actualValue = init[name]
                    field.required === 'true' && field.condition
                      ? (groupObj[field.key] = Yup.number().when(name, {
                          is: String(actualValue) !== String(value),
                          then: Yup.number()
                            .required()
                            .label(field.name)
                            .moreThan(Number(field.min))
                            .max(
                              isNumeric(field.max) === true
                                ? field.max
                                : fieldValue[field.max || '']
                            ),
                          otherwise: Yup.number().notRequired(),
                        }))
                      : field.required === 'true'
                      ? (groupObj[field.key] = Yup.number()
                          .required()
                          .label(field.name)
                          .moreThan(Number(field.min))
                          .max(
                            isNumeric(field.max) === true ? field.max : fieldValue[field.max || '']
                          ))
                      : (groupObj[field.key] = Yup.number().notRequired())
                  } else {
                    init[field.key] = 0
                    const name = field.condition && field.condition.name
                    const value = field.condition && field.condition.value
                    const actualValue = init[name]
                    field.required === 'true' && field.condition
                      ? (groupObj[field.key] = Yup.number().when(name, {
                          is: String(actualValue) !== String(value),
                          then: Yup.number().required().label(field.name).moreThan(0),
                          otherwise: Yup.number().notRequired(),
                        }))
                      : field.required === 'true'
                      ? (groupObj[field.key] = Yup.number()
                          .required()
                          .label(field.name)
                          .moreThan(0))
                      : (groupObj[field.key] = Yup.number().notRequired())
                  }
                }

                if (field.value === 'stringArray') {
                  init[field.key] = [field.values && field.values[0]]
                  field.required === 'true'
                    ? (groupObj[field.key] = Yup.array()
                        .required()
                        .min(1, 'Select at least one option')
                        .label(field.name))
                    : (groupObj[field.key] = Yup.array().notRequired())
                }
                if (field.value === 'objectArray') init[field.key] = []
              }
            } else {
              if (Object.keys(init).includes(field.key)) {
                delete init[field.key]
              }
            }
          } else {
            if (sections.sequenceNumber == 1) {
              mandatoryFields.forEach((field: string) => {
                field === 'termsheetName'
                  ? (init['termsheetName'] = 'My Document')
                  : (init[field] = '')
                if (!fieldList.includes(field)) fieldList.push(field)
              })
              groupObj['termsheetName'] = Yup.string()
                .required()
                .label('Document Name')
                .min(3, 'Template name must be greater than 3 characters')
              groupObj['investorId'] = Yup.string().label('Investor/Fund').optional()
              groupObj['companyName'] = Yup.string().label('Company Name').optional()
            }

            if (field.key !== undefined) {
              fieldList.push(field.key)
              if (field.value === 'boolean') {
                init[field.key] = false
                groupObj[field.key] = Yup.boolean().notRequired()
              }

              if (field.value === 'string' && field.type === 'radio') {
                init[field.key] = field.values && field.values[0]
                field.required === 'true'
                  ? (groupObj[field.key] = Yup.string().required().label(field.name))
                  : (groupObj[field.key] = Yup.string().notRequired())
              } else if (field.value === 'string') {
                init[field.key] = ''
                const name = field.condition && field.condition.name
                const value = field.condition && field.condition.value
                const actualValue = init[name]
                field.required === 'true' && field.condition
                  ? (groupObj[field.key] = Yup.string().when(name, {
                      is: String(actualValue) !== String(value),
                      then: Yup.string().required().label(field.name),
                      otherwise: Yup.string().notRequired(),
                    }))
                  : field.required === 'true'
                  ? (groupObj[field.key] = Yup.string().required().label(field.name))
                  : (groupObj[field.key] = Yup.string().notRequired())
              }

              if (field.value === 'number') {
                if (field.value === 'number' && field.type === 'numberINR') {
                  init[field.key] = '0.00'
                  const name = field.condition && field.condition.name
                  const value = field.condition && field.condition.value
                  const actualValue = init[name]
                  field.required === 'true' && field.condition
                    ? (groupObj[field.key] = Yup.number().when(name, {
                        is: String(actualValue) !== String(value),
                        then: Yup.number()
                          .required()
                          .label(field.name)
                          .moreThan(Number(field.min))
                          .max(
                            isNumeric(field.max) === true ? field.max : fieldValue[field.max || '']
                          ),
                        otherwise: Yup.number().notRequired(),
                      }))
                    : field.required === 'true'
                    ? (groupObj[field.key] = Yup.number()
                        .required()
                        .label(field.name)
                        .moreThan(Number(field.min))
                        .max(
                          isNumeric(field.max) === true ? field.max : fieldValue[field.max || '']
                        ))
                    : (groupObj[field.key] = Yup.number().notRequired())
                } else {
                  init[field.key] = 0
                  const name = field.condition && field.condition.name
                  const value = field.condition && field.condition.value
                  const actualValue = init[name]
                  field.required === 'true' && field.condition
                    ? (groupObj[field.key] = Yup.number().when(name, {
                        is: String(actualValue) !== String(value),
                        then: Yup.number().required().label(field.name).moreThan(0),
                        otherwise: Yup.number().notRequired(),
                      }))
                    : field.required === 'true'
                    ? (groupObj[field.key] = Yup.number().required().label(field.name).moreThan(0))
                    : (groupObj[field.key] = Yup.number().notRequired())
                }
              }

              if (field.value === 'stringArray') {
                init[field.key] = [field.values && field.values[0]]
                field.required === 'true'
                  ? (groupObj[field.key] = Yup.array()
                      .required()
                      .min(1, 'Select at least one option')
                      .label(field.name))
                  : (groupObj[field.key] = Yup.array().notRequired())
              }
              if (field.value === 'objectArray') init[field.key] = []
            }
          }
        })
      })
    }
    if (fieldList.length > 0) detailedObj[camelize(sections.stepname)] = fieldList
    if (Object.keys(groupObj).length > 0) validationSchema.push(Yup.object(groupObj))
  })
  const newSchema = [...validationSchema.slice(0, -1)]
  newSchema.push(validationSchema.slice(-1)[0])
  const [currentSchema, setCurrentSchema] = useState<any>(newSchema[0])

  const getTermsheetDraftData = (values: TermsheetType) => {
    const termsheetData: any = {
      investorId: parseInt(values?.investorId === null ? '0' : values?.investorId.toString()) || 0,
      investorName: values?.investorName,
      templateId: Number(templateData.id),
      termsheetName: values?.termsheetName,
      companyName:
        name || (companyObj && companyObj.companyName) || initialValues?.companyName || '',
      cin:
        companyId ||
        (companyObj && companyObj.companyId) ||
        values?.cin ||
        initialValues?.cin ||
        ' ',
      roundSize:
        values?.roundSize !== undefined ? Math.round(Number(values?.roundSize)).toString() : 0,
      valuation:
        values.isPriced !== undefined
          ? values?.isPriced === 'No'
            ? Math.round(Number(values?.floorValuation)).toString() || 0
            : Math.round(Number(values?.valuation)).toString() || 0
          : values?.valuation !== undefined
          ? Math.round(Number(values?.valuation)).toString()
          : 0,
      expiryDate:
        values?.expiryDate && values?.expiryDate !== ''
          ? formatDate(values.expiryDate, 'dd/MM/yyyy')
          : '',
      investmentAmount:
        values?.investmentAmount !== undefined ? Math.round(values?.investmentAmount) : 0,
      sections: {},
      additionalSections: {},
      companyId:
        values.companyId ||
        (companyObj && companyObj.hissaCompanyId) ||
        initialValues?.hissaCompanyId ||
        '',
      type: docType,
      fileUrl: getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath || '',
      docURL: getTermsheetUrl.data?.getTermsheetUrlPath?.docUrlPath || '',
    }

    jsonData.forEach((section: StepperType) => {
      if (Number(section.sequenceNumber) > 0) {
        const stepName = camelize(section.stepname)
        if (!Object.keys(termsheetData.sections).includes(stepName)) {
          termsheetData.sections[stepName] = {}
        }

        section.groups.forEach((group: GroupsType) => {
          group.fields.forEach((field: FieldsType) => {
            if (section.condition !== undefined) {
              if (values[section.condition.name] === section.condition.value) {
                if (field.condition !== undefined) {
                  if (String(values[field.condition.name]) === String(field.condition.value))
                    termsheetData.sections[stepName][field.key] = values[field.key]
                } else {
                  termsheetData.sections[stepName][field.key] = values[field.key]
                }
              }
            } else {
              if (section.sequenceNumber == 1) {
                mandatoryFields.forEach(
                  (element: string) => (termsheetData.sections[stepName][element] = values[element])
                )
              }
              if (field.condition !== undefined) {
                if (String(values[field.condition.name]) === String(field.condition.value))
                  termsheetData.sections[stepName][field.key] = values[field.key]
              } else {
                termsheetData.sections[stepName][field.key] = values[field.key]
              }
            }
          })
        })
      }
    })

    return termsheetData
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const goTo = (step: number) => {
    if (!stepper.current) {
      return
    }

    stepper.current.goto(step)
    setCurrentSchema(newSchema[stepper.current.currentStepIndex - 1])
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(newSchema[stepper.current.currentStepIndex - 1])

    setCurrentStepIndex(stepper.current.currentStepIndex)
  }

  const convertEditedDocxToPdf = async () => {
    const docxBlob = await container.current?.documentEditor
      .saveAsBlob('Docx')
      .then((blob: Blob) => {
        return blob
      })

    if (docxBlob) {
      const uploadFile = await NewInstance.put(
        getTermsheetUrl.data?.getTermsheetUrlPath?.presignedDocUrl || '',
        docxBlob
      )
      const file = new File([docxBlob], 'termsheet-docx.docx')
      setUpload(file)
      const pdfBlob = await docToPdf(container)
      if (pdfBlob) {
        const file = new File([pdfBlob], 'termsheet-pdf.pdf')
        let base64 = (await convertToBase64(file)) as string
        if (!base64.startsWith('data:application/pdf;base64,'))
          base64 = `data:application/pdf;base64,${base64}`
        setNewPdf(base64)
      }
    }
  }

  const submitStep = async (
    values: TermsheetType,
    formikHelpers?: FormikHelpers<TermsheetType>
  ) => {
    if (!stepper.current) {
      return
    }
    allFields.forEach((section: any) => {
      section.fields.forEach((fields: any) => {
        fields.fields.forEach((field: any) => {
          if (field?.recommended === 'true') {
            const value = values[field.key]
            if (
              (field.type === 'textfield' && (value === '' || value === 0)) ||
              (field.type === 'numberINR' && (value === '0.00' || value === 0 || value === ''))
            ) {
              recommendedFields.push({
                stepName: section.stepName,
                key: field.key,
                name: field.name,
                type: field.type,
              })
            }
          }
        })
      })
    })
    setRecommendedFields(recommendedFields)
    allFields.forEach((section: any) => {
      if (stepper.current?.currentStepIndex === Number(section.step)) {
        section.fields.forEach((fields: any) => {
          fields.fields.forEach((field: any) => {
            if (field?.recommended === 'true') {
              const value = values[field.key]
              if (
                (field.type === 'textfield' && (value === '' || value === 0)) ||
                (field.type === 'numberINR' && (value === '0.00' || value === 0 || value === ''))
              ) {
                Toast(`It is recommended to fill ${field.name}`, ToastType.warning)
              }
            }
          })
        })
      }
    })
    setCurrentSchema(newSchema[Number(stepper.current.currentStepIndex)])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 1) {
        setLoading(true)
        await convertEditedDocxToPdf()
        const uploadFile = await NewInstance.put(
          getTermsheetUrl.data?.getTermsheetUrlPath?.presignedUrl || '',
          final
        )
        const termsheet = getTermsheetDraftData(values)
        termsheet.status = 'Draft'
        if (termsheetId) {
          termsheet.termsheetId = termsheetId
        }
        handleSendDraftTermsheet(termsheet)
        setShowSign(true)
        setDisable(true)
        stepper.current.goNext()
        setIsStepperCollpased(true)
        setLoading(false)
      } else if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 2) {
        setLoading(true)
        setShowSign(false)
        const termsheet = getTermsheetData(values)
        setPreviewUrl('')
        setHissaCompanyId(values.hissaCompanyId)
        setQueryEnabled(true)
        setInvestorId(values.investorId)
        const variablesList: any = {}
        values['investorDetails'] = [
          {
            name: values.investorName,
            security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
            conversionRatio: values.conversionRatio1 ? values.conversionRatio1 : undefined,
            email: values.investorEmail ? values.investorEmail : undefined,
            address: values.investorAddress ? values.investorAddress : undefined,
            nominalEquity: values?.nominalEquityShares1 ? values?.nominalEquityShares1 : 0,
            shares: [
              values?.investor1NoOfShares1 !== '' ? Math.round(values?.investor1NoOfShares1) : 0,
              values?.investor1NoOfShares2 !== '' ? Math.round(values?.investor1NoOfShares2) : 0,
            ],
            tranches: [
              values?.investor1tranche1 !== undefined ? Math.round(values?.investor1tranche1) : 0,
              values?.investor1tranche2 !== undefined ? Math.round(values?.investor1tranche2) : 0,
              values?.investor1tranche3 !== undefined ? Math.round(values?.investor1tranche3) : 0,
            ],
          },
          values?.investorName2 &&
          (values.investor2SecurityType ||
            values.conversionRatio2 ||
            values.investor2Email ||
            values.investor2Address ||
            values.investor2tranche1 ||
            values.investor2tranche2 ||
            values.investor2tranche3)
            ? {
                name: values?.investorName2 ? values.investorName2 : undefined,
                security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
                conversionRatio: values.conversionRatio2 ? values.conversionRatio2 : undefined,
                email: values?.investor2Email ? values.investor2Email : undefined,
                address: values.investor2Address ? values.investor2Address : undefined,
                nominalEquity: values?.nominalEquityShares2 ? values?.nominalEquityShares2 : 0,
                shares: [
                  values?.investor2NoOfShares1 !== ''
                    ? Math.round(values?.investor2NoOfShares1)
                    : 0,
                  values?.investor2NoOfShares2 !== ''
                    ? Math.round(values?.investor2NoOfShares2)
                    : 0,
                ],
                tranches:
                  values?.investor2tranche1 !== '0.00'
                    ? [
                        values?.investor2tranche1 !== undefined
                          ? Math.round(values?.investor2tranche1)
                          : 0,
                        values?.investor2tranche2 !== undefined
                          ? Math.round(values?.investor2tranche2)
                          : 0,
                        values?.investor2tranche3 !== undefined
                          ? Math.round(values?.investor2tranche3)
                          : 0,
                      ]
                    : undefined,
              }
            : undefined,
          values?.investorName3 &&
          (values.investor3SecurityType ||
            values.conversionRatio3 ||
            values.investor3Email ||
            values.investor3Address ||
            values.investor3tranche1 ||
            values.investor3tranche2 ||
            values.investor3tranche3)
            ? {
                name: values?.investorName3 ? values.investorName3 : undefined,
                security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
                conversionRatio: values.conversionRatio3 ? values.conversionRatio3 : undefined,
                email: values?.investor3Email ? values.investor3Email : undefined,
                address: values.investor3Address ? values.investor3Address : undefined,
                nominalEquity: values?.nominalEquityShares3 ? values?.nominalEquityShares3 : 0,
                shares: [
                  values?.investor3NoOfShares1 !== ''
                    ? Math.round(values?.investor3NoOfShares1)
                    : 0,
                  values?.investor3NoOfShares2 !== ''
                    ? Math.round(values?.investor3NoOfShares2)
                    : 0,
                ],
                tranches:
                  values?.investor3tranche1 !== '0.00'
                    ? [
                        values?.investor3tranche1 !== undefined
                          ? Math.round(values?.investor3tranche1)
                          : 0,
                        values?.investor3tranche2 !== undefined
                          ? Math.round(values?.investor3tranche2)
                          : 0,
                        values?.investor3tranche3 !== undefined
                          ? Math.round(values?.investor3tranche3)
                          : 0,
                      ]
                    : undefined,
              }
            : undefined,
          values?.investorName4 &&
          (values.investor4SecurityType ||
            values.conversionRatio4 ||
            values.investor4Email ||
            values.investor4Address ||
            values.investor4tranche1 ||
            values.investor4tranche2 ||
            values.investor4tranche3)
            ? {
                name: values?.investorName4 ? values.investorName4 : undefined,
                security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
                conversionRatio: values.conversionRatio4 ? values.conversionRatio4 : undefined,
                email: values?.investor4Email ? values.investor4Email : undefined,
                address: values.investor4Address ? values.investor4Address : undefined,
                nominalEquity: values?.nominalEquityShares4 ? values?.nominalEquityShares4 : 0,
                shares: [
                  values?.investor4NoOfShares1 !== ''
                    ? Math.round(values?.investor4NoOfShares1)
                    : 0,
                  values?.investor4NoOfShares2 !== ''
                    ? Math.round(values?.investor4NoOfShares2)
                    : 0,
                ],
                tranches:
                  values?.investor4tranche1 !== '0.00'
                    ? [
                        values?.investor4tranche1 !== undefined
                          ? Math.round(values?.investor4tranche1)
                          : 0,
                        values?.investor4tranche2 !== undefined
                          ? Math.round(values?.investor4tranche2)
                          : 0,
                        values?.investor4tranche3 !== undefined
                          ? Math.round(values?.investor4tranche3)
                          : 0,
                      ]
                    : undefined,
              }
            : undefined,
          values?.investorName5 &&
          (values.investor5SecurityType ||
            values.conversionRatio5 ||
            values.investor5Email ||
            values.investor5Address ||
            values.investor5tranche1 ||
            values.investor5tranche2 ||
            values.investor5tranche3)
            ? {
                name: values?.investorName5 ? values.investorName5 : undefined,
                security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
                conversionRatio: values.conversionRatio5 ? values.conversionRatio5 : undefined,
                email: values?.investor5Email ? values.investor5Email : undefined,
                address: values.investor5Address ? values.investor5Address : undefined,
                nominalEquity: values?.nominalEquityShares5 ? values?.nominalEquityShares5 : 0,
                tranches:
                  values?.investor5tranche1 !== '0.00'
                    ? [
                        values?.investor5tranche1 !== undefined
                          ? Math.round(values?.investor5tranche1)
                          : 0,
                        values?.investor5tranche2 !== undefined
                          ? Math.round(values?.investor5tranche2)
                          : 0,
                        values?.investor5tranche3 !== undefined
                          ? Math.round(values?.investor5tranche3)
                          : 0,
                      ]
                    : undefined,
              }
            : undefined,
        ]
        if (values['keyManInsurance'] !== 'at least INR ') {
          values['keyManInsuranceadditional'] = 0
        }
        jsonData.forEach((sections: StepperType) => {
          if (Number(sections.sequenceNumber) > 0) {
            sections.groups.forEach((group: GroupsType) => {
              group.fields.forEach((field: FieldsType) => {
                if (sections.condition !== undefined) {
                  if (values[sections.condition.name] === sections.condition.value) {
                    if (field.condition !== undefined) {
                      if (String(values[field.condition.name]) === String(field.condition.value))
                        variablesList[field.key] = values[field.key].toString()
                    } else {
                      variablesList[field.key] = values[field.key].toString()
                    }
                  }
                } else {
                  if (sections.sequenceNumber == 1) {
                    mandatoryFields.forEach((element: string) => {
                      if (
                        !Object.keys(variablesList).find(
                          (item: any) => item.variableName === element
                        )
                      )
                        variablesList[element] =
                          (values[element] && values[element].toString()) || ''
                    })
                  }
                  if (field.condition !== undefined) {
                    if (String(values[field.condition.name]) === String(field.condition.value))
                      variablesList[field.key] = values[field.key].toString()
                  } else {
                    variablesList[field.key] = values[field.key].toString()
                  }
                }
              })
            })
          }
        })
        variablesList['Image'] = signs?.getTrimmedCanvas().toDataURL('image/png') || ' '
        values['Image'] = signs?.getTrimmedCanvas().toDataURL('image/png') || ' '
        variablesList['investorDetails'] = termsheet.investorDetails
        // values['investorDetails'] = termsheet.investorDetails
        usePreviewTermsheetMutation.mutate({
          companyId: values.hissaCompanyId,
          templateName: 'Termsheet',
          contextVariables: [],
          investorId: parseInt(values?.investorId === null ? '0' : values?.investorId.toString()),
          variablesList: values,
          templateId: Number(templateData.id),
        })
        stepper.current.goNext()
        setIsStepperCollpased(true)
        setLoading(false)
      } else if (
        stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 3 &&
        (stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber + 1 ||
          stepper.current?.currentStepIndex === 0 ||
          stepper.current?.currentStepIndex === undefined)
      ) {
        setLoading(true)
        const termsheet = getTermsheetDraftData(values)
        termsheet.status = 'Draft'
        if (termsheetId) {
          termsheet.termsheetId = termsheetId
        }
        handleSendDraftTermsheet(termsheet)
        stepper.current.goNext()
        setLoading(false)
      } else if (
        stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber + 1 ||
        stepper.current?.currentStepIndex === 0 ||
        stepper.current?.currentStepIndex === undefined
      ) {
        const termsheet = getTermsheetDraftData(values)
        termsheet.status = 'Draft'
        if (termsheetId) {
          termsheet.termsheetId = termsheetId
        }
        handleSendDraftTermsheet(termsheet)
        stepper.current.goNext()
      }
    } else {
      setLoading(true)
      const termsheet = getTermsheetData(values)
      // const file = await convertBase64ToBlob(newPdf.data, 'docx')
      // const uploadFile = await NewInstance.put(
      //   getTermsheetUrl.data?.getTermsheetUrlPath?.presignedUrl || '',
      //   final
      // )
      termsheet.fileUrl = getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath || ''
      termsheet.docURL = getTermsheetUrl.data?.getTermsheetUrlPath?.docUrlPath || ''
      if (termsheetId) {
        termsheet.termsheetId = termsheetId
      }
      handleSendTermsheet(termsheet)
      // docToPdf(container, values)
    }
  }

  const reloadStepper = () => {
    if (StepperComponent.hasInstace(stepperRef.current as HTMLDivElement))
      StepperComponent.destroy(stepperRef.current as HTMLDivElement)
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    reloadStepper()
  }, [allowedSections])

  useEffect(() => {
    reloadStepper()
  }, [aiInits])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef, jsonData])

  function getKeys(element: string) {
    let value = ''
    Object.keys(detailedObj).forEach((key: string) => {
      if (detailedObj[key].includes(element)) value = key
    })
    return value
  }
  const handleStepperErrors = (errors: FormikErrors<TermsheetType>) => {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((keyName: string) => {
        const field = getKeys(keyName)
        if (errors[keyName]) {
          const stepperError = stepperErrors
          stepperError[field] = true
          setStepperErrors(stepperError)
        }
      })
    }
    Object.keys(detailedObj).forEach((key: string) => {
      if (Object.keys(errors).length === 0) {
        const stepperError = stepperErrors
        stepperError[key] = false
        setStepperErrors(stepperError)
      }
    })
  }

  const [isStepperCollapsed, setIsStepperCollpased] = useState(false)
  const [isImportModel, setIsImportModel] = useState(false)

  const filledValue: string[] = []
  if (aiInits !== undefined) {
    Object.keys(aiInits).map((obj, index) => {
      if (aiInits[obj] !== '' && aiInits[obj] != 0) {
        filledValue.push(obj)
      }
    })
  }

  return mutateSendTermsheet !== undefined && mutateSendTermsheet.isSuccess ? (
    <EmailTermsheet
      termsheetId={termsheetId}
      showModal={showModal}
      type={docType}
      handleClose={() => setShowModal(false)}
    />
  ) : (
    // <Navigate to='/deals' />
    <>
      <div className='card my-3 ps-5 d-flex'>
        <div>
          <div className='card shadow-sm mw-full h-405px mx-2 my-2 card-hover p-0'>
            <div className='card-body d-flex'>
              <div className='w-375px'>
                <div className='d-flex justify-content-between py-2'>
                  <h3 className='mt-3 '>{templateData?.name}</h3>
                  {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => {
              MenuComponent.reinitialization()
            }}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen052.svg'
              className='svg-icon-muted svg-icon-2x'
            />
          </button> */}
                  {/* <TemplateDropdown templateData={templateData} /> */}
                </div>
                <div className='card card-body border p-1 h-225px overflow-hidden'>
                  <img
                    alt=''
                    className='w-100'
                    src={
                      templateData?.thumbnailURL
                        ? query.data?.urlForS3Object!
                        : toAbsoluteUrl('/media/illustrations/misc/termsheet2.png')
                    }
                  />
                </div>
                <div className='mt-1'>
                  <p className='fs-8'>Features</p>
                  <p className='fs-9 h-25px'>
                    {/* {tags}{' '}
            {!tags || tags?.length === 0 ? (
              <span className={`badge fs-9 fw-semibold mb-1 me-2`}>
                <br />
              </span>
            ) : (
              <></>
            )} */}
                  </p>
                </div>
                {/* <div className='mt-2'>
          <span className='fs-8 pt-5 mt-5'> Issued To -</span>

          {companyIssuedTo?.map((c) => (
            <span className='float-end fs-base'>{c}</span>
          ))}
        </div> */}
                {/* <div className='pt-2'> */}
                {/* <button
              className='float-end btn btn-lg btn-icon'
              onClick={() => {
                setDownload(true)
              }}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-1' />
            </button> */}
                {/* <button
            className='float-end btn btn-sm btn-icon'
            onClick={() => {
              setShowModel(true)
            }}
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
          </button>
        </div> */}
              </div>
              <div className='p-5 overflow-auto scrollable h-350px'>
                {templateDetails?.map((d) => (
                  <div className='col flex-nowrap'>
                    <h1>{d.title}</h1>
                    <h4>{d.description}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='border border-dashed border-primary border-1 bg-termsheet-bg rounded my-8 p-9'
        style={{ cursor: 'pointer' }}
      >
        <div
          className='d-flex flex-row '
          onClick={() => {
            useChatBot.setIsOpen(true)
          }}
        >
          <span className='me-6'>
            <img
              src={'/media/icons/duotune/general/ai-bot.svg'}
              alt=''
              className='w-100px h-150px'
            />
          </span>

          <span className='fs-8'>
            <h3 className='fw-bold mb-0'>Newly Introducing! Hissa's new AI Powered Template</h3>
            <div className='text-gray-500 fs-7 pb-2'>
              This Template is filled using AI, the following fields are detected from our
              conversation
            </div>
            {aiInits && aiInits !== undefined && (
              <>
                <div>The Fields detected via our conversation are</div>

                <div
                  className='row card-body overflow-auto flex-nowrap my-2 mx-0'
                  style={{ overflowX: 'auto', scrollbarWidth: 'none' }}
                >
                  {filledValue.map((obj, index) => {
                    if (aiInits[obj] !== '' && aiInits[obj] != 0) {
                      return index <= 2 ? (
                        <span className='border border-dashed border-gray-400 rounded p-4 py-2 bg-white w-auto me-4'>
                          <h6
                            className='m-0 fw-bold pb-1'
                            data-toggle='tooltip'
                            data-placement='top'
                            title={aiInits[obj]}
                          >
                            {aiInits[obj].length > 20
                              ? aiInits[obj].substring(0, 20) + '...'
                              : aiInits[obj]}
                          </h6>
                          <h5 className='fw-bold m-0 text-primary pb-0'>
                            {getNameForKey(obj, templateData.jsonData)}

                            <span>
                              <KTSVG
                                path='/media/icons/duotune/general/stars.svg'
                                className='svg-icon-6 ms-2 '
                              />
                            </span>
                          </h5>
                        </span>
                      ) : (
                        <></>
                      )
                    }
                  })}
                  {filledValue.length > 3 && (
                    <span className='border border-dashed border-gray-400 rounded pt-6 px-6 bg-white w-auto me-3'>
                      <h5 className='text-primary fs-bold d-flex align-items-baseline'>
                        {'+' + (filledValue.length - 3)}
                      </h5>
                    </span>
                  )}
                </div>

                <div className='pt-6'>
                  Few of the below fields have not been detected by the AI, please go through the
                  document and fill in the necessary fields.{' '}
                </div>
              </>
            )}
          </span>

          <div className='col-6 d-flex justify-content-end align-items-end'>
            <button
              type='button'
              className='px-4 rounded border border-1 border-hover-primary border-growth-card bg-transparent h-38px fw-bold text-growth-card cursor-pointer bg-hover-secondary text-hover-primary'
              onClick={(event: any) => {
                event.stopPropagation()
                setIsImportModel(true)
              }}
            >
              <KTSVG
                path='/media/icons/duotune/files/fil021.svg'
                className='svg-icon-2x text-growth-card'
              />{' '}
              Import from Modelling
            </button>
          </div>
        </div>
      </div>
      {isImportModel && (
        <ImportInvestmentModel
          handleClose={() => {
            setIsImportModel(false)
          }}
          templateData={templateData}
          showModal={isImportModel}
          allCompanies={allCompanies}
          initialValues={initialValues}
        />
      )}

      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex gx-8'
        id='kt_create_account_stepper'
      >
        <div
          ref={wizardRef}
          className={`card d-flex justify-content-center justify-content-xl-start flex-row-auto col-12 mh-700px min-h-700px ${
            isStepperCollapsed ? 'col-md-1' : 'col-md-3'
          }`}
        >
          <div
            className={`d-flex flex-row-reverse`}
            onClick={() => {
              setIsStepperCollpased(!isStepperCollapsed)
            }}
          >
            <KTSVG
              className={`svg-icon-3x cursor-pointer ${isStepperCollapsed ? 'me-10' : ''}`}
              path={
                isStepperCollapsed
                  ? `/media/icons/duotune/arrows/arr080.svg`
                  : `/media/icons/duotune/arrows/arr079.svg`
              }
            />
          </div>
          <div className='card-body p-5 overflow-auto'>
            <div className='card-body shadow-sm stepper-nav '>
              {jsonData.map((sections: any) => {
                if (Number(sections.sequenceNumber) > 0) {
                  if (!stepperValues.includes(sections.stepname)) {
                    stepperValues.push(sections.stepname)
                    return (
                      <div
                        className={`${
                          Number(sections.sequenceNumber) === 1 ? 'stepper-item' : 'stepper-item'
                        }`}
                        data-kt-stepper-element='nav'
                      >
                        <div className='stepper-wrapper cursor-pointer'>
                          <div
                            className='stepper-icon w-40px h-40px'
                            onClick={() => {
                              goTo(sections.sequenceNumber)
                            }}
                          >
                            {stepperErrors[camelize(sections.stepname)] ? (
                              <i className='stepper-check fas fa-exclamation'></i>
                            ) : (
                              <i className='stepper-check fas fa-check'></i>
                            )}{' '}
                            <span className='stepper-number'>{sections.sequenceNumber}</span>
                          </div>
                          {!isStepperCollapsed && (
                            <div
                              className='stepper-label'
                              onClick={() => {
                                goTo(sections.sequenceNumber)
                              }}
                            >
                              <h3 className='stepper-title'>{sections.stepname}</h3>

                              <div className='stepper-desc fw-semibold'>
                                Enter {sections.stepname}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='stepper-line h-40px'></div>
                      </div>
                    )
                  }
                }
              })}

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper cursor-pointer'>
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>{newSchema.length + 1}</span>
                  </div>

                  {!isStepperCollapsed && (
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Edit {jsonData[0].type}</h3>
                      <div className='stepper-desc fw-semibold'>Preview and edit details</div>
                    </div>
                  )}
                </div>
                <div className='stepper-line h-40px'></div>
              </div>
              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper cursor-pointer'>
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>{newSchema.length + 2}</span>
                  </div>

                  {!isStepperCollapsed && (
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Preview and Sign {jsonData[0].type}</h3>
                      <div className='stepper-desc fw-semibold'>Preview details before sending</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`col-12 ${isStepperCollapsed ? 'col-md-11' : 'col-md-9'}`}>
          <div className='card ms-5 px-5 pt-5 mb-5 mh-700px min-h-700px overflow-auto'>
            <Formik
              initialValues={aiInits || initialValues || init}
              validationSchema={currentSchema}
              onSubmit={submitStep}
              // enableReinitialize={true}
              validateOnChange={true}
              // validateOnBlur={true}
            >
              {({ errors, values, getFieldProps, resetForm }) => {
                handleStepperErrors(errors)
                setFieldValue(values)
                setCompanyName(initialValues && initialValues?.companyName)

                const handleGenerateForm = (data: any) => {
                  const d: TermsheetType = {}
                  if (data && data.length > 0) {
                    data.forEach((item: any) => {
                      d[item.key] = item.value
                    })
                  }
                  const getAiInits = getInits1(data, JSON.parse(templateData.jsonData))

                  const mergedState = aiInits
                    ? {
                        ...Object.entries(values).reduce((acc: any, [key, value]) => {
                          if (value === '' || Number(value) === 0) {
                            acc[key] = value
                          }
                          return acc
                        }, {}),
                        ...Object.entries(aiInits).reduce((acc: any, [key, value]) => {
                          if (value !== '') {
                            acc[key] = value
                          }
                          return acc
                        }, {}),
                        ...Object.entries(getAiInits).reduce((acc: any, [key, value]) => {
                          if (value !== '') {
                            acc[key] = value
                          }
                          return acc
                        }, {}),
                      }
                    : {
                        ...Object.entries(values).reduce((acc: any, [key, value]) => {
                          if (value === '' || Number(value) === 0) {
                            acc[key] = value
                          }
                          return acc
                        }, {}),
                        ...Object.entries(getAiInits).reduce((acc: any, [key, value]) => {
                          if (value !== '') {
                            acc[key] = value
                          }
                          return acc
                        }, {}),
                      }
                  setAiInits(mergedState)
                  reloadStepper()
                }
                if (values['investor1NoOfShares1'] || values['nominalEquityShares1']) {
                  values['preferenceShares1'] =
                    values['investor1NoOfShares1'] - values['nominalEquityShares1']
                }
                if (values['investor2NoOfShares1'] || values['nominalEquityShares2']) {
                  values['preferenceShares2'] =
                    values['investor2NoOfShares1'] - values['nominalEquityShares2']
                }
                if (values['investor3NoOfShares1'] || values['nominalEquityShares3']) {
                  values['preferenceShares3'] =
                    values['investor3NoOfShares1'] - values['nominalEquityShares3']
                }
                if (values['investor4NoOfShares1'] || values['nominalEquityShares4']) {
                  values['preferenceShares4'] =
                    values['investor4NoOfShares1'] - values['nominalEquityShares4']
                }

                return (
                  <div>
                    {(stepper.current?.currentStepIndex == 1 ||
                      stepper.current?.currentStepIndex == undefined) && (
                      <TermSheetName
                        isEdit={isEdit}
                        setTemplateName={setTemplateName}
                        templateName={templateName || ''}
                        setIsEdit={setIsEdit}
                      />
                    )}

                    {jsonData[0].type === 'Termsheet' &&
                      (stepper.current?.currentStepIndex == 1 ||
                        stepper.current?.currentStepIndex == undefined) && (
                        <GetInvestors investorList={investorList} values={values} />
                      )}

                    {jsonData[0].type === 'Termsheet' &&
                      (stepper.current?.currentStepIndex == 1 ||
                        stepper.current?.currentStepIndex == undefined) && (
                        <GetCompanies
                          stepper={stepper || {}}
                          setCompanyObj={setCompanyObj}
                          setCompanyName={setCompanyName}
                          values={values}
                          isFromModelling={initialValues?.isFromModelling ? true : false}
                          resetForm={() => {
                            init['isFromModelling'] = undefined
                            resetForm({ values: init })
                          }}
                          templateData={templateData}
                        />
                      )}

                    <Form noValidate id='kt_create_account_form'>
                      {jsonData.map((sections: StepperType) => {
                        if (Number(sections.sequenceNumber) > 0) {
                          const elements =
                            (sections.condition &&
                              getFieldProps(sections.condition?.name).value ===
                                sections.condition?.value &&
                              (setFormikValues(getFieldProps(sections.condition?.name).value),
                              (
                                <div
                                  className={`${
                                    String(sections.sequenceNumber) === '1' ? 'current' : ''
                                  }`}
                                  data-kt-stepper-element='content'
                                >
                                  <GenerateTermsheet sections={sections} aiInits={aiInits} />
                                </div>
                              ))) ||
                            (!sections.condition && (
                              <div
                                className={`${
                                  String(sections.sequenceNumber) === '1' ? 'current' : ''
                                }`}
                                data-kt-stepper-element='content'
                              >
                                <GenerateTermsheet sections={sections} aiInits={aiInits} />
                              </div>
                            ))
                          steppers[sections.stepname] = elements
                          return elements
                        }
                      })}

                      <div data-kt-stepper-element='content'>
                        {usePreviewTermsheetMutation !== undefined &&
                        usePreviewTermsheetMutation.isSuccess &&
                        previewUrl ? (
                          ((fieldValue.status = 'Preview'),
                          (
                            // <Preview
                            //   url={usePreviewTermsheetMutation.data?.data.presignedUrl || ''}
                            // />
                            <>
                              {/* <div className='fv-row mb-4  overflow-scroll h-550px '>
                                  <LazyPdfDocument url={previewUrl} />
                                </div>
                                <div className='p-3'>
                                  <button
                                    className='btn btn-secondary '
                                    onClick={(e) => {
                                      handleDownload()
                                      e.preventDefault()
                                    }}
                                  >
                                    Download Docx
                                  </button>
                                  <div className='pt-5'>
                                    <label className='form-label '>upload the Final Docx</label>
                                    <input
                                      className='form-control form-control-sm '
                                      type='file'
                                      onChange={(e: any) => {
                                        handleUpload(e?.target?.files[0])
                                      }}
                                    />
                                  </div>
                                </div> */}
                              {/* <button onClick={docToPdf}>Convert to pdf</button> */}
                              <div className='flex-row col-12 pt-5'>
                                <DocumentEditorContainerComponent
                                  ref={container}
                                  height='590'
                                  toolbarItems={items}
                                  enableToolbar={true}
                                  showPropertiesPane={false}
                                  serviceUrl='https://syncfusion-api.hissa.com/api/documenteditor/'
                                  toolbarClick={(e) => {
                                    handleToolbarClick(e)
                                  }}
                                >
                                  <Inject services={[Toolbar]}></Inject>
                                </DocumentEditorContainerComponent>
                                <div className='mt-2 text-primary'>
                                  Note: Do not edit the words which are enclosed with #
                                  i.e(#investorName#)
                                </div>
                              </div>
                              {/* <PdfViewerComponent ref={(scope) => { pdfviewer = scope }}
                              id="container" style={{height:'590'}}
                              documentPath="https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf"
                              serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer">
    
                              </PdfViewerComponent> */}
                            </>
                          ))
                        ) : usePreviewTermsheetMutation !== undefined &&
                          usePreviewTermsheetMutation.isLoading ? (
                          <h6 className='text-center'>
                            Loading...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </h6>
                        ) : (
                          <span>Failed to load preview </span>
                        )}
                      </div>
                      <div
                        data-kt-stepper-element='content'
                        className='d-flex justify-content-center'
                      >
                        {/* {showSign ? (
                          <> */}
                        {showSign && (
                          <div className='fv-row mb-4  overflow-scroll h-550px '>
                            <LazyPdfDocument url={newPdf} />
                          </div>
                        )}
                        {/* <div className=''>
                              <Select
                                options={signOptions}
                                className='w-100 pb-3 '
                                onChange={(e: any) => {
                                  if (e.value === 1) {
                                    setImageInput(true)
                                    setSignInput(false)
                                  } else if (e.value === 2) {
                                    setImageInput(false)
                                    setSignInput(true)
                                  }
                                }}
                                placeholder='Select Signature Type'
                                styles={selectCustomStyles}
                              />
                              {imageInput && (
                                <div className='pb-3'>
                                  <input
                                    accept='image/png,image/jpeg'
                                    className='form-control'
                                    type='file'
                                    onChange={(e: any) => {
                                      handleImage(e?.target?.files[0])
                                    }}
                                  />
                                </div>
                              )}
                              {signInput && (
                                <div className='border border-primary h-200px pb-3 '>
                                  <SignatureCanvas
                                    penColor='black'
                                    ref={(data) => setSigns(data)}
                                    canvasProps={{
                                      width: 300,
                                      height: 200,
                                      className: 'sigCanvas',
                                    }}
                                  />
                                </div>
                              )}
                              <button
                                className='btn btn-secondary '
                                onClick={(e) => {
                                  handlesign()
                                  e.preventDefault()
                                }}
                              >
                                Submit Sign
                                {submitLoding && (
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                )}
                              </button>
                            </div>
                          </>
                        ) : (
                          <div></div>
                        )} */}
                      </div>
                      {jsonData[0]?.draftNotes &&
                        (stepper.current?.currentStepIndex ===
                          stepper.current?.totatStepsNumber! - 2 ||
                          stepper.current?.currentStepIndex == undefined) && (
                          <DraftNotes jsonData={jsonData} />
                        )}
                      {(stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 2 ||
                        stepper.current?.currentStepIndex == undefined) && (
                        <RecommendedFields fields={recFields} values={values} />
                      )}
                      <div
                        className={`d-flex ${
                          stepper.current?.currentStepIndex !== 1
                            ? 'justify-content-between'
                            : 'justify-content-end'
                        } pt-5`}
                      >
                        <div className='btn-toolbar'>
                          <button
                            onClick={() => {
                              prevStep()
                              setDisable(false)
                            }}
                            type='button'
                            className='btn btn-secondary m-1'
                            data-kt-stepper-action='previous'
                          >
                            Back
                          </button>
                        </div>

                        {currentStepIndex && (
                          <div className='btn-toolbar'>
                            {stepper.current?.currentStepIndex ===
                              stepper.current?.totatStepsNumber! &&
                              stepper.current?.currentStepIndex !== undefined && (
                                <button
                                  onClick={() => {
                                    navigate('/deals')
                                    Toast('Draft Created Successfully!', ToastType.success)
                                  }}
                                  type='button'
                                  className='btn btn-secondary m-1'
                                >
                                  Save as Draft
                                </button>
                              )}
                            <button
                              ref={submitBtnRef}
                              type='submit'
                              className='btn btn-primary m-1'
                              disabled={loading}
                            >
                              <span className='indicator-label'>
                                {
                                  (!loading &&
                                    stepper.current?.currentStepIndex ===
                                      stepper.current?.totatStepsNumber! - 1 &&
                                    'Save & Preview') ||
                                    (!loading &&
                                      (stepper.current?.currentStepIndex !==
                                        stepper.current?.totatStepsNumber! ||
                                        stepper.current?.currentStepIndex === 0 ||
                                        stepper.current?.currentStepIndex === undefined) &&
                                      'Continue') ||
                                    (!loading && mutateSendTermsheet.isSuccess) /*&& 'Sent'*/
                                }
                                {stepper.current?.currentStepIndex ===
                                  stepper.current?.totatStepsNumber! &&
                                  stepper.current?.currentStepIndex !== undefined &&
                                  'Share'}
                              </span>
                              {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Loading...
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </Form>
                    <div className='card my-3 ps-5 d-flex'>
                      <HissaAiModal
                        schema={JSON.parse(templateData.jsonData)}
                        showModal={showFreeTextModal}
                        handleClose={handleFreeTextModalClose}
                        handleGenerateForm={handleGenerateForm}
                        // setResponse={(message: string) => {
                        //   setResponse(message)
                        // }}
                      />
                    </div>
                  </div>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export { SendTermsheetWizard }
