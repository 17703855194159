import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { IAddPartnerData, addPartnerInits, addPartnerSchema } from './AddPartnerHelper'
import _ from 'lodash'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import {
  AddPartnersGroupMutation,
  AddPartnersGroupMutationVariables,
  EditPartnersGroupMutation,
  EditPartnersGroupMutationVariables,
  FundStructure,
  FundUnit,
  PartnerGroup,
  PartnerType,
  useAddPartnersGroupMutation,
  useEditPartnersGroupMutation,
  useGetFundStructureQuery,
  useGetFundUnitQuery,
  useGetPartnersGroupQuery,
} from '../../../../generated/graphql'
import Select from 'react-select'
import { MultiSelectOptionType, SelectOptionType } from '../../../../utils/MultiSelect'
import { selectCustomStyles } from '../../../../utils/Select'
import { countryData } from '../../../../utils/CountryData'
import { KTSVG } from '../../../../_metronic/helpers'

type Props = {
  fundStructure: FundStructure
  investorId: number
  handleClose: VoidFunction
  isEdit: boolean
  data?: PartnerGroup
  fundUnitData: FundUnit[]
}

type Partner = {
  name: string
  country: string
}

type Fund = {
  fundUnitId: number
  noOfUnits: number
}

function AddPartnerForm(props: Props) {
  const initialData: IAddPartnerData = {
    name: props?.data?.name!,
    partnerType:
      PartnerType.GeneralPartner === props?.data?.groupType
        ? PartnerType.GeneralPartner
        : PartnerType.LimitedPartner,
    isAddPartner: props.data?.partners?.length! > 0 ? true : false,
    isAddFundUnit: props.data?.fundUnits?.length! > 0 ? true : false,
    partnerName: '',
    isGroup: true,
    country: 'India',
    contribution: props?.data?.contribution!,
    unit: '',
    noOfUnits: 1,
  }

  const [data, setData] = useState<IAddPartnerData>(
    _.cloneDeep(props.isEdit ? initialData : addPartnerInits)
  )
  const [loading, setLoading] = useState(false)
  const [isEditPartners, setIsEditPartner] = useState(false)
  const [country, setCountry] = useState<any>({ value: 97, label: 'India' })
  const [partnersList, setPartnersList] = useState<any>()
  const [fundList, setFundList] = useState<any>(props.data?.fundUnits)
  const [availableUnits, setAvailableUnits] = useState<number>(0)
  const [selectedFund, setSelectedFund] = useState<any>()
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [isEditTransaction, setIsEditTransaction] = useState(false)
  const [amountError, setAmountError] = useState(false)
  const countryList: MultiSelectOptionType[] = []
  const partnerTypes: SelectOptionType[] = [
    { label: 'General Partner', value: 'GeneralPartner' },
    { label: 'Limited Partner', value: 'LimitedPartner' },
  ]
  countryData.forEach((country, index) => {
    countryList.push({ value: index, label: country.name })
  })

  const [fundUnitNames, setFundUnitnames] = useState<SelectOptionType[]>([
    { label: '--Select--', value: '' },
  ])

  useEffect(() => {
    if (!props.isEdit && props.fundUnitData.length > 0) {
      props.fundUnitData.forEach((unit: FundUnit) => {
        const groupObj = { label: unit.name!, value: unit.id }
        const index = fundUnitNames.findIndex((object) => object.value === unit.id)
        if (index === -1)
          fundUnitNames
            ? setFundUnitnames((prev: any) => [...prev, groupObj])
            : setFundUnitnames([groupObj])
      })
    }
    if (props.isEdit && props.fundUnitData.length > 0 && props.data?.fundUnits?.length! > 0) {
      const groupNames: any = []
      props.data?.fundUnits?.forEach((unit) => {
        groupNames.push(getUnitName(Number(unit!.fundUnitId)))
      })
      props.fundUnitData.forEach((unit: FundUnit) => {
        const groupObj = { label: unit.name!, value: unit.id }
        const index = fundUnitNames.findIndex((object) => object.value === unit.id)
        if (index === -1 && !groupNames.includes(unit.name!))
          fundUnitNames
            ? setFundUnitnames((prev: any) => [...prev, groupObj])
            : setFundUnitnames([groupObj])
      })
    }
  }, [props.fundUnitData])

  useEffect(() => {
    if (props.data?.partners?.length! > 0) {
      const dataList: any = []
      props.data?.partners?.forEach((partnerObject) => {
        const partnerObj = { name: partnerObject!.name, country: partnerObject!.country }
        dataList.push(partnerObj)
      })
      setPartnersList([...dataList])
    }
  }, [props.data])

  useEffect(() => {
    if (fundList && fundList.length > 0) {
      let total = 0
      fundList.forEach((fund: Fund) => {
        total += Number(fund.noOfUnits) * getNAV(Number(fund.fundUnitId))
      })
      setTotalAmount(total)
    }
  }, [fundList])

  const mutateAddPartner = useAddPartnersGroupMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddPartnersGroupMutation,
        _variables: AddPartnersGroupMutationVariables,
        _context: unknown
      ) => {
        setData(_.cloneDeep(addPartnerInits))
        const compKey = {
          input: { input: props.investorId },
        }
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey(compKey))
        queryClient.invalidateQueries(
          useGetPartnersGroupQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructure.id },
          })
        )
        queryClient.invalidateQueries(
          useGetFundUnitQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructure.id },
          })
        )
        Toast('Partner Added Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const mutateEditPartner = useEditPartnersGroupMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: EditPartnersGroupMutation,
        _variables: EditPartnersGroupMutationVariables,
        _context: unknown
      ) => {
        setData(_.cloneDeep(addPartnerInits))
        const compKey = {
          input: { input: props.investorId },
        }
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey(compKey))
        queryClient.invalidateQueries(
          useGetPartnersGroupQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructure.id },
          })
        )
        queryClient.invalidateQueries(
          useGetFundUnitQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructure.id },
          })
        )
        Toast('Partner Updated Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const partnerTableHeader = () => {
    return (
      <tr className='fw-bold text-gray-600 '>
        <th className='fs-5 min-w-150px'>Name</th>
        <th className='fs-5 min-w-125px text-center'>Country</th>
      </tr>
    )
  }

  const fundTableHeader = () => {
    return (
      <tr className='fw-bold text-gray-600 '>
        <th className='fs-5 min-w-150px'>Fund Unit</th>
        <th className='fs-5 min-w-125px text-center'>Number of Units</th>
        <th className='fs-5 min-w-125px text-center'>Transaction Amount</th>
      </tr>
    )
  }

  const formik = useFormik<IAddPartnerData>({
    initialValues: data,
    validationSchema: addPartnerSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        props.isEdit
          ? mutateEditPartner.mutate({
              input: {
                groupName: data.name,
                investorId: props.investorId,
                groupType: data.partnerType,
                isPartnerPresent: data.isAddPartner,
                fundStructureId: props.fundStructure.id,
                partners: data.isAddPartner && partnersList !== undefined ? partnersList : [],
                isPartnerChanges: isEditPartners,
                groupId: props.data?.id,
                isFundDataChanges: isEditTransaction,
                contribution: data.contribution,
                fundUnits: data.isAddFundUnit && fundList !== undefined ? fundList : [],
              },
            })
          : mutateAddPartner.mutate({
              input: {
                groupName: data.name,
                investorId: props.investorId,
                partnerType: data.partnerType,
                isPartnerPresent: data.isAddPartner,
                fundStructureId: props.fundStructure.id,
                partners: data.isAddPartner && partnersList !== undefined ? partnersList : [],
                contribution: data.contribution,
                fundUnits: data.isAddFundUnit && fundList !== undefined ? fundList : [],
              },
            })
        setLoading(false)
        props.handleClose()
      }, 1000)
    },
  })

  useEffect(() => {
    if (
      props.fundStructure.fundSize &&
      formik.values.contribution > 0 &&
      props.fundStructure.fundSize < formik.values.contribution
    ) {
      setAmountError(true)
    } else if (
      props.fundStructure.fundSize &&
      formik.values.contribution > 0 &&
      props.fundStructure.fundSize >= formik.values.contribution
    ) {
      setAmountError(false)
    }
  }, [props.fundStructure.fundSize, formik.values.contribution])

  const [partnerType, setPartnerType] = useState<string>(
    formik.values.partnerType === PartnerType.LimitedPartner ? 'Limited Partner' : 'General Partner'
  )

  function AddIndividualPartners() {
    const partnerObj = { name: formik.values.partnerName, country: formik.values.country }
    const index =
      partnersList &&
      partnersList?.findIndex(
        (object: Partner) =>
          object.name === formik.values.partnerName && object.country === formik.values.country
      )

    if (index === -1 || index === undefined) {
      partnersList
        ? setPartnersList((prev: any) => [...prev, partnerObj])
        : setPartnersList([partnerObj])
      formik.setFieldValue('partnerName', '')
      formik.setFieldValue('country', 'India')
    } else {
      Toast('Partner entry already exist', ToastType.error)
    }
  }

  function getUnitName(unitId: number) {
    let fundName = ''
    props.fundUnitData.forEach((unit) => {
      if (Number(unit.id) === unitId) fundName = unit.name!
    })
    return fundName
  }

  function getNAV(unitId: number) {
    let nav = 1
    props.fundUnitData.forEach((unit) => {
      if (Number(unit.id) === unitId) nav = Number(unit.nav!)
    })
    return nav
  }

  function AddFundContribute() {
    const partnerObj = {
      fundUnitId: Number(formik.values.unit),
      noOfUnits: formik.values.noOfUnits,
    }
    const index =
      fundList &&
      fundList?.findIndex(
        (object: Fund) =>
          object.fundUnitId === Number(formik.values.unit) &&
          object.noOfUnits === formik.values.noOfUnits
      )

    if (index === -1 || index === undefined) {
      fundList ? setFundList((prev: any) => [...prev, partnerObj]) : setFundList([partnerObj])
      formik.setFieldValue('unit', '')
      formik.setFieldValue('noOfUnits', 1)
      setSelectedFund([{ label: '--Select--', value: '' }])
    } else {
      Toast('Fund entry already exist', ToastType.error)
    }

    const selectedIndex =
      fundUnitNames &&
      fundUnitNames?.findIndex((object: any) => object.value === formik.values.unit)
    const updatedList = fundUnitNames.filter(function (element: any, index: number) {
      return index !== selectedIndex
    })
    setFundUnitnames(updatedList)
    setTotalAmount(
      (prev) => prev + Number(formik.values.noOfUnits) * getNAV(Number(formik.values.unit))
    )
  }

  function getAvailabelUnits(unitId: number) {
    let freeUnits = 0
    props.fundUnitData.map((unit) => {
      if (unitId === Number(unit.id)) freeUnits = Number(unit.freeUnits)
    })

    const index = props.data?.fundUnits?.findIndex(
      (object: any) => Number(object.fundUnitId) === unitId
    )

    if (index! > -1) {
      const currentData = props.data?.fundUnits![index!]
      freeUnits += Number(currentData?.noOfUnits)
    }

    return freeUnits
  }

  function deletePartners(partnerName: string, country: string) {
    const selectedIndex =
      partnersList &&
      partnersList?.findIndex(
        (object: Partner) => object.name === partnerName && object.country === country
      )
    const updatedList = partnersList.filter(function (element: any, index: number) {
      return index !== selectedIndex
    })
    setPartnersList([...updatedList])
    setIsEditPartner(true)
  }

  async function getUpdatedList(unit: Fund) {
    const selectedIndex =
      fundList &&
      fundList?.findIndex(
        (object: Fund) =>
          object.fundUnitId === unit.fundUnitId && object.noOfUnits === unit.noOfUnits
      )

    const updatedList = fundList.filter(function (element: any, index: number) {
      return index !== selectedIndex
    })

    return updatedList
  }

  function editPartners(partnerName: string, country: string) {
    const selectedIndex =
      partnersList &&
      partnersList?.findIndex(
        (object: Partner) => object.name === partnerName && object.country === country
      )
    const updatedList = partnersList.filter(function (element: any, index: number) {
      return index !== selectedIndex
    })
    setPartnersList([...updatedList])
    formik.setFieldValue('partnerName', partnerName)
    formik.setFieldValue('country', country)
    setCountry({ value: 0, label: country })
  }

  async function updateFundUnitDropdown(unit: Fund, fundName: string) {
    const groupObj = { label: fundName, value: unit.fundUnitId }
    const index =
      fundList &&
      fundList.findIndex((object: any) => Number(object.value) === Number(unit.fundUnitId))
    if (index === -1)
      fundUnitNames
        ? setFundUnitnames((prev: any) => [...prev, groupObj])
        : setFundUnitnames([groupObj])
  }

  async function editFundContribution(unit: Fund) {
    const updatedList = await getUpdatedList(unit)
    setFundList([...updatedList])
    const fundName = getUnitName(Number(unit.fundUnitId))
    setAvailableUnits(getAvailabelUnits(Number(unit.fundUnitId)))
    formik.setFieldValue('unit', unit.fundUnitId)
    formik.setFieldValue('noOfUnits', Number(unit.noOfUnits))
    setSelectedFund({ label: fundName, value: unit.fundUnitId })
    await updateFundUnitDropdown(unit, fundName)
  }

  async function deleteFundContribution(unit: Fund) {
    const fundName = getUnitName(Number(unit.fundUnitId))
    const updatedList = await getUpdatedList(unit)
    setFundList([...updatedList])
    await updateFundUnitDropdown(unit, fundName)
    setIsEditTransaction(true)
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='w-100 mx-auto'>
        <div className='row'>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Group Name</label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Group Name'
              {...formik.getFieldProps('name')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>
              Group Contribution <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Group contribution'
              {...formik.getFieldProps('contribution')}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched && amountError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{`Amount should be less than  ${props.fundStructure.fundSize?.toLocaleString(
                    'en-IN'
                  )}`}</div>
                </div>
              )}
            </div>
            <div className='text-danger mt-2'>
              {formik.touched.contribution && formik.errors.contribution && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.contribution}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Select Group Type</label>
            <Select
              options={partnerTypes}
              name='partnerType'
              placeholder={partnerType}
              onChange={(e: any) => (
                setPartnerType(e.label), formik.setFieldValue('partnerType', e.value)
              )}
              styles={selectCustomStyles}
            />

            <div className='text-danger mt-2'>
              {formik.touched.partnerType && formik.errors.partnerType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.partnerType}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='row align-items-baseline pb-4 pt-6'>
            <div className='col-12 col-sm-8'>
              <div className='d-flex justify-content-between'>
                <label className='form-label required'>Add contribution to each fund unit</label>
                <div className='form-check form-switch form-check-custom form-check-solid align-items-baseline'>
                  <input
                    className='form-check-input h-20px w-40px'
                    type='checkbox'
                    checked={formik.values.isAddFundUnit}
                    disabled={formik.values.contribution <= 0}
                    {...formik.getFieldProps('isAddFundUnit')}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {formik.values.isAddFundUnit && (
          <div className='row'>
            <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
              <label className='form-label required'>Select Fund Unit</label>
              <Select
                options={fundUnitNames}
                name='unit'
                onChange={(e: any) => {
                  setSelectedFund(e)
                  setAvailableUnits(getAvailabelUnits(Number(e.value)))
                  formik.setFieldValue('unit', e.value)
                }}
                value={selectedFund}
                styles={selectCustomStyles}
              />
            </div>

            <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
              <label className='form-label required'>Number of Units</label>

              <input
                className='form-control form-control-lg form-control-solid'
                placeholder='Enter Number of Units'
                {...formik.getFieldProps('noOfUnits')}
                type='number'
              />
              <div className='text-success mt-2'>
                <div className='fs-7'>
                  <div className='fv-help-block'>
                    {formik.values.unit !== '' ? 'Available units : ' + availableUnits : ''}
                  </div>
                </div>
              </div>
              <div className='text-danger mt-2'>
                {formik.touched.noOfUnits && formik.errors.noOfUnits && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.noOfUnits}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4 pt-md-11'>
              <button
                type='button'
                className='btn btn-sm btn-primary '
                disabled={
                  formik.values.unit === '' ||
                  formik.errors.unit !== undefined ||
                  formik.errors.noOfUnits !== undefined ||
                  formik.values.noOfUnits > availableUnits
                }
                onClick={() => {
                  AddFundContribute()
                  setIsEditTransaction(true)
                }}
              >
                Add
              </button>
            </div>

            {fundList?.length > 0 && (
              <div className='col-md-12 pe-10 d-flex justify-content-left pt-2 pb-4'>
                <div className='table-responsive'>
                  <div className='col card mh-350px border border-gray-300 p-4 overflow-auto'>
                    <table className='table align-middle gs-0 gy-4 table-row-dashed border-gray-200'>
                      <thead className='border-bottom'>{fundTableHeader()}</thead>
                      <tbody>
                        {fundList?.map((unit: Fund, index: number) => (
                          <>
                            {unit && (
                              <tr key={index} className=''>
                                <td className=''>
                                  <p className='fs-base mb-0 fw-bold'>
                                    {getUnitName(Number(unit.fundUnitId))}
                                  </p>
                                </td>
                                <td>
                                  <p className='fs-base mb-0 text-center'>{unit.noOfUnits}</p>
                                </td>
                                <td>
                                  <p className='fs-base mb-0 text-center'>
                                    {Number(
                                      unit.noOfUnits * getNAV(Number(unit.fundUnitId))
                                    ).toLocaleString('en-IN')}
                                  </p>
                                </td>

                                <td>
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-secondary ms-md-2 mt-1 mt-md-0'
                                    onClick={() => {
                                      editFundContribution(unit)
                                    }}
                                  >
                                    <span className='svg-icon svg-icon-2 text-center'>
                                      <KTSVG
                                        path='/media/icons/duotune/art/art005.svg'
                                        className='svg-icon-2'
                                      />
                                    </span>
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-secondary ms-md-2 mt-1 mt-md-0'
                                    onClick={() => {
                                      deleteFundContribution(unit)
                                    }}
                                  >
                                    <span className='svg-icon svg-icon-2 text-center'>
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon-2'
                                      />
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className='row'>
          <div className='row align-items-baseline pb-4 pt-6'>
            <div className='col-12 col-sm-8'>
              <div className='d-flex justify-content-between'>
                <label className='form-label'>Do You Want To Add Individual Partner details?</label>
                <div className='form-check form-switch form-check-custom form-check-solid align-items-baseline'>
                  <input
                    className='form-check-input h-20px w-40px'
                    type='checkbox'
                    checked={formik.values.isAddPartner}
                    {...formik.getFieldProps('isAddPartner')}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {formik.values.isAddPartner && (
          <div className='row'>
            <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
              <label className='form-label required'>Partner Name</label>

              <input
                className='form-control form-control-lg form-control-solid'
                placeholder='Enter Partner Name'
                {...formik.getFieldProps('partnerName')}
              />
              <div className='text-danger mt-2'>
                {formik.touched.partnerName && formik.errors.partnerName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.partnerName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
              <label className='form-label required'>Select Country</label>
              <Select
                options={countryList}
                name='country'
                placeholder={formik.values.country}
                menuPlacement='auto'
                maxMenuHeight={150}
                onChange={(e: any) => (setCountry(e), formik.setFieldValue('country', e.label))}
                styles={selectCustomStyles}
                value={country}
              />

              <div className='text-danger mt-2'>
                {formik.touched.country && formik.errors.country && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.country}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4 pt-9'>
              <button
                type='button'
                className='btn btn-sm btn-primary '
                disabled={
                  formik.values.partnerName === '' || formik.errors.partnerName !== undefined
                }
                onClick={() => {
                  AddIndividualPartners()
                  setCountry({ value: 97, label: 'India' })
                  setIsEditPartner(true)
                }}
              >
                Add
              </button>
            </div>

            {partnersList?.length > 0 && (
              <div className='col-md-12 pe-10 d-flex justify-content-left pt-2 pb-4'>
                <div className='table-responsive'>
                  <div className='col card mh-350px border border-gray-300 p-4 overflow-auto'>
                    <table className='table align-middle gs-0 gy-4 table-row-dashed border-gray-200'>
                      <thead className='border-bottom'>{partnerTableHeader()}</thead>
                      <tbody>
                        {partnersList?.map((partner: any, index: number) => (
                          <>
                            {partner && (
                              <tr key={index} className=''>
                                <td className=''>
                                  <p className='fs-base mb-0 fw-bold'>{partner.name}</p>
                                </td>
                                <td>
                                  <p className='fs-base mb-0 text-center'>{partner.country}</p>
                                </td>

                                <td>
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-secondary ms-md-2 mt-1 mt-md-0'
                                    onClick={() => {
                                      editPartners(partner.name, partner.country)
                                    }}
                                  >
                                    <span className='svg-icon svg-icon-2 text-center'>
                                      <KTSVG
                                        path='/media/icons/duotune/art/art005.svg'
                                        className='svg-icon-2'
                                      />
                                    </span>
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-secondary ms-md-2 mt-1 mt-md-0'
                                    onClick={() => {
                                      deletePartners(partner.name, partner.country)
                                    }}
                                  >
                                    <span className='svg-icon svg-icon-2 text-center'>
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon-2'
                                      />
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className='text-danger mt-2'>
          {formik.values.contribution > 0 &&
            formik.values.contribution !== totalAmount &&
            formik.values.isAddFundUnit && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{`Transaction Amount ${Number(
                  totalAmount
                ).toLocaleString('en-IN')} not matching with Group Contribution`}</div>
              </div>
            )}
        </div>
        <div className='d-flex justify-content-between pt-15'>
          <button
            type='button'
            className='btn btn-lg btn-secondary me-3'
            onClick={props.handleClose}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={
              loading ||
              (formik.values.contribution > 0 && formik.values.contribution !== totalAmount) ||
              amountError
            }
          >
            {!loading && (props.isEdit ? 'Edit Partner Group' : 'Add Partner Group')}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default AddPartnerForm
