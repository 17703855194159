import { ChangeEvent, useEffect, useState } from 'react'
import Select from 'react-select'
import {
  CompanyObject,
  DeleteLinksMutation,
  DeleteLinksMutationVariables,
  Document_Vault,
  GetCompanyDataQuery,
  RemoveObjectMutation,
  RemoveObjectMutationVariables,
  UploadFileToCompanyMutation,
  UploadFileToCompanyMutationVariables,
  useDeleteLinksMutation,
  useGetCompanyDataQuery,
  useRemoveObjectMutation,
  useUploadFileToCompanyMutation,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import { multiSelectCustomStyles, MultiSelectOptionType } from '../../../../../utils/MultiSelect'
import Toast, { ToastType } from '../../../../../utils/toast'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import FileUpload from '../FileUpload'
import { FilesTable } from './FilesTable'
import _ from 'lodash'
import { postInvestorDocuments } from '../../../../../api/investorApi'
import { getUserInvestors } from '../../../../../utils/LocalStorageUtils'

type Props = {
  name: string
  companyId: string
  hissaVerified: boolean
  cId: string
  isIndirect?: boolean
  companyData?: CompanyObject
}

function DocumentsView(props: Props) {
  const userInvestors = getUserInvestors('userInvestors')

  const investors: MultiSelectOptionType[] = userInvestors.map((inv) => {
    return {
      label: inv.investorName,
      value: inv.investorId,
    }
  })
  const [investorId, setInvestorId] = useState<number>(
    investors && investors.length > 0 ? (investors[0].value as number) : -1
  )
  const { data, isFetched } = useGetCompanyDataQuery<GetCompanyDataQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        id: investorId,
        companyName: props.name,
        cin: props.companyId,
        vaultType: Document_Vault.Investor,
        cId: props.cId,
        hissaVerified: props.hissaVerified,
        isIndirect: props.isIndirect,
      },
    },
    { enabled: investors && investors.length > 0 }
  )

  const getcompanyDataQuery = useGetCompanyDataQuery.getKey()
  const mutateUploadFile = useUploadFileToCompanyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: UploadFileToCompanyMutation,
        _variables: UploadFileToCompanyMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(getcompanyDataQuery)
        handleBreadcrumb(folder[folder.length - 1], folder.length - 1)
        Toast('Added Successfully!', ToastType.success)
      },
    },
    {}
  )
  const deleteObject = useRemoveObjectMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveObjectMutation,
        _variables: RemoveObjectMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(getcompanyDataQuery)
        Toast('Deleted Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const mutateDeleteLinks = useDeleteLinksMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DeleteLinksMutation,
        _variables: DeleteLinksMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(getcompanyDataQuery)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const [folder, setFolder] = useState<any[]>(['Home'])
  let directoryData: any = []
  if (data?.data.length > 0) {
    directoryData.push(...data?.data)
  }
  const [dirData, setDirData] = useState<any>()
  const [showAddFolder, setShowAddFolder] = useState<boolean>(false)
  const [dirList, setDirList] = useState<any[]>([])
  const [orgDirList, setOrgDirList] = useState<any[]>([])
  const [fileList, setFileList] = useState<any[]>([])

  const [showModal, setShowModal] = useState(false)
  const [key, setkey] = useState<String>('')
  useEffect(() => {
    setDirList(dirData)
    dirList?.map((dir) => {
      if (dir.count === 0 || dir.count === undefined) {
        setFileList((fileList) => _.uniq([...fileList, dir]))
      } else {
        setFileList((fileList) => _.uniq([...fileList, dir]))

        setDirList((dirList) => _.uniq([...dirList, ...dir.items]))
      }
    })
  }, [dirData])

  useEffect(() => {
    handleBreadcrumb(folder[folder.length - 1], folder.length - 1)
  }, [data])

  if (!isFetched && investors && investors.length > 0) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  function handleDirectoryClick(key: string) {
    const data = dirData.filter((item: { name: string }) => item.name === key)[0].items
    setDirData(data)
    setOrgDirList(data)
    setFolder([...folder, key])
  }

  function handleAddFolderClose() {
    setShowAddFolder(false)
  }

  async function handleCreateFolder(name: string) {
    setShowAddFolder(false)
    let key: string = ''
    folder.forEach((e) => {
      if (e != 'Home') {
        key += e + '/'
      }
    })
    key += name
    if (props.hissaVerified) {
      mutateUploadFile.mutate({
        input: {
          name: '',
          cin: props.companyId,
          file: null,
          folder: key,
          investorId: investorId,
          hissaCompanyId: props.cId,
          hissaVerified: props.hissaVerified,
          companyName: props.name,
        },
      })
      await postInvestorDocuments([
        {
          name: key + '/',
          key: key + '/',
          size: 0,
          cin: props.companyId,
          tags: [],
          investorId: Number(investorId),
          companyId: props.cId,
          hissaVerified: props.hissaVerified,
          companyName: props.name,
        },
      ])
    } else {
      mutateUploadFile.mutate({
        input: {
          name: '',
          cin: props.companyId,
          file: null,
          folder: key,
          investorId: investorId,
          companyId: Number(props.cId),
          hissaVerified: props.hissaVerified,
          companyName: props.name,
        },
      })
      await postInvestorDocuments([
        {
          name: key + '/',
          key: key + '/',
          size: 0,
          cin: props.companyId,
          tags: [],
          investorId: Number(investorId),
          companyId: props.cId,
          hissaVerified: props.hissaVerified,
          companyName: props.name,
        },
      ])
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let filteredFileList: any[] = []
    if (e.target.value.length > 0) {
      fileList.forEach((file) => {
        if (file.count === undefined) {
          let fileName = file.name.split('/') || file.name
          fileName = fileName[fileName.length - 1].toLowerCase()

          file.tags.map((tag: string) => {
            if (tag.toLowerCase().includes(e.target.value.toLowerCase())) {
              filteredFileList.push(file)
            }
          })
          if (fileName.includes(e.target.value.toLowerCase())) {
            filteredFileList.push(file)
          }
        }
      })
      setDirData(_.uniq(filteredFileList))
    } else {
      setDirData(orgDirList)
    }
  }

  function handleBreadcrumb(item: string, endIndex: number) {
    if (item === 'Home') {
      setDirData(directoryData)
      setOrgDirList(directoryData)
      setFolder(['Home'])
    } else {
      let data = directoryData
      for (let i = 1; i < endIndex + 1; i++) {
        const index = data.findIndex((item: any) => {
          return item.key === folder[i]
        })
        if (index !== -1) {
          data = data[index].items
        }
      }
      setDirData(data)
      setOrgDirList(data)
      setFolder(folder.slice(0, endIndex + 1))
    }
  }

  function handleFundSelect(e: any) {
    setInvestorId(Number(e.value))
    queryClient.invalidateQueries(getcompanyDataQuery)
    handleBreadcrumb('Home', 0)
  }

  function handleFileUpload() {
    let name: string = ''
    folder.forEach((e) => {
      if (e != 'Home') {
        name += e + '/'
      }
    })
    setShowModal(true)
    setkey(name)
  }

  function handleFileUploadClose() {
    queryClient.invalidateQueries(getcompanyDataQuery)
    setShowModal(false)
  }

  async function handleDeleteItem(item: any) {
    let directory: string = ''
    folder.forEach((e) => {
      if (e != 'Home') {
        directory += e + '/'
      }
    })
    directory = directory.substring(0, directory.length - 1)
    const itemName = item.name.split('/')
    if (item.source === 'link') {
      mutateDeleteLinks.mutate({
        input: {
          id: item.linkId,
          investorId: investorId,
        },
      })
    }
    if (item.size) {
      deleteObject.mutate({
        input: {
          fileName: itemName[itemName.length - 1],
          cin: props.companyId,
          folder: directory,
          investorId: investorId,
          hissaCompanyId: props.hissaVerified ? props.cId : null,
          companyId: props.hissaVerified ? null : Number(props.cId),
          hissaVerified: props.hissaVerified,
          companyName: props.name,
        },
      })
    } else {
      deleteObject.mutate({
        input: {
          cin: props.companyId,
          folder:
            directory != ''
              ? directory + '/' + itemName[itemName.length - 1]
              : itemName[itemName.length - 1],
          investorId: investorId,
          hissaVerified: props.hissaVerified,
          hissaCompanyId: props.hissaVerified ? props.cId : null,
          companyId: props.hissaVerified ? null : Number(props.cId),
          companyName: props.name,
        },
      })
    }
    MenuComponent.hideDropdowns(undefined)
  }

  const breadCrumbs = folder.map((item, index) => {
    if (index !== folder.length - 1) {
      return (
        <li className='breadcrumb-item' key={item + index}>
          <p
            onClick={() => handleBreadcrumb(item, index)}
            className='mb-0 text-primary fw-bold fs-base cursor-pointer'
          >
            {item}
          </p>
        </li>
      )
    } else if (index === folder.length - 1) {
      return (
        <li className='breadcrumb-item fs-base fw-bold' aria-current='page' key={item + index}>
          {item}
        </li>
      )
    }
  })

  return (
    <div className='row'>
      {investors && investors.length > 0 && (
        <div className='card card-body'>
          <div className='row'>
            <h1 className='m-3'>Documents</h1>
          </div>
          <div className='row ps-3'>
            <div className='col-md-5'>
              <div className='pb-5'>
                <Select
                  className='w-100 text-primary'
                  options={investors}
                  defaultValue={investors[investors.findIndex((i) => i.value == investorId)]}
                  styles={multiSelectCustomStyles}
                  onChange={(e) => {
                    handleFundSelect(e)
                  }}
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='pb-5'>
                <input
                  type='text'
                  className='form-control form-control-sm w-80 form-control-solid me-2 fs-base'
                  name='search'
                  placeholder='Search...'
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
              </div>
            </div>
            <div className='col-md-4 align-items-end'>
              <button
                className='btn btn-primary fs-base fw-bold float-md-end ms-3'
                onClick={() => setShowAddFolder(true)}
                disabled={showAddFolder || (folder.length > 1 && folder[1] === 'Transaction')}
              >
                + Add Folder
              </button>
              <button
                className='btn btn-secondary fs-base fw-bold float-md-end'
                onClick={() => handleFileUpload()}
                disabled={showModal || (folder.length > 1 && folder[1] === 'Transaction')}
              >
                Upload
              </button>
            </div>
            {investorId && (
              <FileUpload
                folder={key}
                cin={props.companyId}
                show={showModal}
                handleClose={handleFileUploadClose}
                investorId={investorId}
                vaultType={Document_Vault.Investor}
                cId={props.cId}
                hissaVerified={props.hissaVerified}
                companyName={props.name}
              />
            )}
          </div>
          <div className=''>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb ps-5'>{breadCrumbs}</ol>
            </nav>
            <div className='row g-6 g-xl-9 mb-xl-9'>
              <div className='col-12 col-sm-12 col-xl'>
                <FilesTable
                  handleDeleteItem={handleDeleteItem}
                  handleCreateFolder={handleCreateFolder}
                  folderData={dirData}
                  handleAddFolderClose={handleAddFolderClose}
                  showAddFolder={showAddFolder}
                  handleDirectoryClick={handleDirectoryClick}
                  companyName={props.name}
                  companyId={props.companyId}
                  investorId={investorId}
                  vaultType={Document_Vault.Investor}
                  folderName={folder}
                  cId={props.cId}
                  hissaVerified={props.hissaVerified}
                  isGeneric={false}
                  companyData={props.companyData}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DocumentsView
