import { useFormik } from 'formik'
import React, { ChangeEvent, useState } from 'react'
import {
  CompanyObject,
  CompanyTypes,
  EditValuationDetailsMutation,
  EditValuationDetailsMutationVariables,
  ValuationDetailsObject,
  useEditValuationDetailsMutation,
  useGetAllTransactionsQuery,
  useGetCompanyWiseDataQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import Select from 'react-select'
import { KTSVG } from '../../../../../_metronic/helpers'
import { SelectOptionType } from '../../../../../utils/MultiSelect'
import { selectCustomStyles } from '../../../../../utils/Select'
import Toast, { ToastType } from '../../../../../utils/toast'
import {
  EditValuationDetails,
  IValuationDetails,
  editValuationDetailInits,
  editValuationDetailSchema,
} from '../HissaValuationDetails/AddValuationDetailsHelper'
import { hexToRGB } from '../../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'

type props = {
  closeModal: () => void
  companyDetails: CompanyObject
  valuationDetails: ValuationDetailsObject
}

function EditHissaValuationDetailsForm(props: props) {
  const tagsObjList: { value?: string; label?: string }[] = []
  const tagsList: string[] = (props.valuationDetails.tags as string[]) || []
  tagsList?.map((tag: string) => tagsObjList.push({ value: tag, label: tag }))

  const [data, setData] = useState<EditValuationDetails>(editValuationDetailInits)
  const [loading, setLoading] = useState(false)
  const [customTag, setCustomTag] = useState<boolean>(false)
  const [values, setValues] = useState<any>(tagsObjList)
  const [inputText, setInputText] = useState<string>('')
  const [disable, setDisable] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [isFile, setIsFile] = useState<string>(
    props.valuationDetails.fileUrl?.split('/').pop() || ''
  )
  const [fileError, setFileError] = useState<boolean>(false)
  const [fileErrorMessage, setFileErrorMessage] = useState<string>('')

  let date = new Date()
  let maxDate = date.toISOString().split('T')[0]

  const initValues: IValuationDetails = {
    name: props.valuationDetails.valuationName,
    valuationDate: new Date(props.valuationDetails.valuationDate).toISOString()?.split('T')[0],
    valuationType: props.valuationDetails.valuationType || '',
    methodology: props.valuationDetails.methodology || '',
    tags: (props.valuationDetails.tags as string[]) || [],
    sharePrice: props.valuationDetails.sharePrice || 0,
    sharePricePerReport: props.valuationDetails.sharePricePerReport || 0,
    sharePriceBookValue: props.valuationDetails.sharePriceBookValue || 0,
    valuatorName: props.valuationDetails.valuatorName || '',
    isDefault: props.valuationDetails.isDefault || false,
    valuationReport: isFile,
  }

  const [badgeList] = useState<SelectOptionType[]>([
    { value: 'Add New', label: 'Add New' },
    { value: 'DCF Method', label: 'DCF Method' },
    { value: 'Precedent Transaction Method', label: ' Precedent Transaction Method' },
  ])

  const handleChange = (e: any) => {
    setValues(e)

    e?.forEach((ele: any) => {
      if (ele.value === 'Add New') {
        setValues((values: any) => {
          return values?.filter((element: any) => element?.value !== 'Add New')
        })
        setCustomTag((customTag) => {
          return !customTag
        })
      }
    })
  }

  const changeHandler = async (event: any) => {
    const allowedTypes = ['pdf', 'docx']
    const filename = event.target.files[0].name
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
    if (!allowedTypes.includes(extension)) {
      setFileError(true)
      formik.setFieldValue('valuationReport', '')
      setFileErrorMessage('File must be PDF or Document')
      setFile(undefined)
    } else if (event.target.files[0].size > 100e6) {
      setFileError(true)
      setFileErrorMessage('File must be lesser than 100 MB')
      setFile(undefined)
    } else if (event && event.target.files.length > 0 && event.target.files[0].size < 100e6) {
      setFile(event.target.files[0])
      formik.setFieldValue('valuationReport', event.target.value)
      setFileError(false)
      setFileErrorMessage('')
    }
  }

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
    if (e.target.value && e.target.value.length > 0) {
      values.forEach((badge: any) => {
        if (badge.value === e.target.value) {
          setDisable(true)
          setErrorMessage('Multiple tags with the same name are not allowed ')
        } else {
          setDisable(false)
          setErrorMessage('')
        }
      })
    }
  }

  const formik = useFormik<IValuationDetails>({
    initialValues: initValues,
    validationSchema: editValuationDetailSchema,
    // validateOnMount: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (finalValues) => {
      // uploadFiles()
      const tags: string[] = []

      values.map((element: any) => tags.push(element.value))

      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, finalValues)
        setData(updatedData)
        mutateEditValuationDetails.mutate({
          input: {
            valuationName: finalValues.name,
            valuationDate: new Date(finalValues.valuationDate).toISOString(),
            valuationType: finalValues.valuationType,
            methodology: finalValues.methodology,
            file: file,
            tags: tags,
            hissaCompanyId: props.companyDetails.hissaCompanyId,
            companyName: props.companyDetails.name,
            hissaVerified: props.companyDetails.type === CompanyTypes.Hissa ? true : false,
            id: props.valuationDetails.id,
            sharePrice: Number(finalValues.sharePrice),
            sharePriceBookValue: Number(finalValues.sharePriceBookValue),
            sharePricePerReport: Number(finalValues.sharePricePerReport),
            valuatorName: finalValues.valuatorName,
            isValuationReport: data.valuationReport === '' ? false : true,
          },
        })
        setLoading(false)
      }, 1000)
    },
  })

  const mutateEditValuationDetails = useEditValuationDetailsMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: EditValuationDetailsMutation,
        _variables: EditValuationDetailsMutationVariables,
        _context: unknown
      ) => {
        props.closeModal()
        if (props.companyDetails) {
          const compKey = {
            input: {
              type: props.companyDetails?.type,
              hissaCompanyId: props.companyDetails?.hissaCompanyId || '',
              id: Number(props.companyDetails?.id) || 0,
            },
          }
          const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
          queryClient.invalidateQueries(getCompanyWiseQuery)
        }

        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)
        Toast('Valuation Details Edited Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='w-100 mx-auto'>
        <div className='d-flex mb-8'>
          <div className='fs-4 fw-bold'>Valuation Details</div>
          <hr className='my-auto flex-grow-1' />
        </div>
        <div className='row'>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Valuation Name</label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Valuation Name'
              {...formik.getFieldProps('name')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Date of Valuation</label>

            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Valuation Date'
              max={maxDate}
              {...formik.getFieldProps('valuationDate')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.valuationDate && formik.errors.valuationDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.valuationDate}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label '>Valuation Methodology</label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Valuation Methodology'
              {...formik.getFieldProps('methodology')}
            />
          </div>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label '>Valuation Type</label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Valuation Type'
              {...formik.getFieldProps('valuationType')}
            />
          </div>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label '>Valuator Name</label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Valuator Name'
              {...formik.getFieldProps('valuatorName')}
            />
          </div>
        </div>

        <div className='row'>
          <div className='fv-row mb-6 col-12 col-sm-6 pe-4'>
            <label className='form-label '>Valuation Report</label>
            <div>
              {isFile === '' ? (
                <div>
                  <div className='input-group'>
                    <input
                      type='file'
                      className='form-control form-control-md form-control-solid text-primary'
                      accept='.doc, .docx,.pdf,'
                      {...formik.getFieldProps('valuationReport')}
                      onChange={(e) => {
                        changeHandler(e)
                      }}
                    />
                    {formik.values.valuationReport !== '' && (
                      <button
                        className='btn btn-sm btn-outline'
                        type='button'
                        onClick={() => {
                          formik.setFieldValue('valuationReport', '')
                          setFileError(false)
                          setFile(undefined)
                          setFileErrorMessage('')
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs012.svg'
                          className='svg-icon-1 svg-icon-gray-500'
                        />
                      </button>
                    )}
                  </div>
                  <div className='text-danger mt-2'>
                    {fileError && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{fileErrorMessage}</div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <span
                  className={`badge fs-9 fw-semibold mb-1 me-2 p-2`}
                  style={{
                    background: hexToRGB(getCSSVariableValue('--kt-donut-' + (3 % 10)), 0.2),
                    color: hexToRGB(getCSSVariableValue('--kt-donut-' + (3 % 10)), 1),
                  }}
                >
                  <p
                    className={`${isFile.length > 45 ? 'text-truncate' : ''} mb-0`}
                    data-toggle='tooltip'
                    data-placement='top'
                    title={isFile}
                    style={{ maxWidth: '200px' }}
                  >
                    {isFile}
                  </p>
                  {/* {isFile} */}
                  <div
                    className='ps-4'
                    onClick={() => {
                      setIsFile('')
                      formik.setFieldValue('valuationReport', '')
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-gray-header ps-2'
                      path='/media/icons/duotune/general/gen040.svg'
                    />
                  </div>
                </span>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 pe-4'>
            <label className='form-label '>Tags</label>
            <div className=''>
              {customTag === false ? (
                <Select
                  className='form-control-solid'
                  options={badgeList}
                  placeholder={'--Select--'}
                  value={values}
                  isMulti={true}
                  onChange={(e: any) => handleChange(e)}
                  styles={selectCustomStyles}
                />
              ) : (
                <div className='d-flex'>
                  <div className='me-4'>
                    <input
                      className='form-control form-control-solid me-2'
                      placeholder='Enter Tag Name'
                      onChange={(e) => handleChangeValue(e)}
                    />
                    {errorMessage && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errorMessage}</div>
                      </div>
                    )}
                    {}
                  </div>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    type='submit'
                    disabled={disable}
                    onClick={(e) => {
                      setValues((values: any) => [
                        ...values,
                        { value: inputText, label: inputText },
                      ])
                      setCustomTag(false)
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr012.svg'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    onClick={() => {
                      setCustomTag(false)
                      setErrorMessage('')
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='d-flex mb-8 pt-4'>
          <div className=' fs-4'>Share Price Details </div>
          <hr className=' my-auto flex-grow-1' />
        </div>

        <div className='row'>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>
              Share Price as per Report <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Share Price'
              {...formik.getFieldProps('sharePricePerReport')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.sharePricePerReport && formik.errors.sharePricePerReport && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.sharePricePerReport}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className={`form-label ${props.valuationDetails.isDefault ? 'required' : ''}`}>
              Share Price FMV <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Share Price'
              {...formik.getFieldProps('sharePrice')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.sharePrice && formik.errors.sharePrice && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.sharePrice}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label'>
              Share Price on Books <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </label>

            <input
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Share Price'
              {...formik.getFieldProps('sharePriceBookValue')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.sharePriceBookValue && formik.errors.sharePriceBookValue && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.sharePriceBookValue}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between pt-15'>
          <button className='btn btn-lg btn-secondary me-3' onClick={props.closeModal}>
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={loading || mutateEditValuationDetails.isLoading}
          >
            {!loading && !mutateEditValuationDetails.isLoading && 'Update'}
            {mutateEditValuationDetails.isLoading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}

            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default EditHissaValuationDetailsForm
