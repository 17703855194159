import * as Yup from 'yup'

export interface IAddFundUnitData {
  name: string
  class: string
  nav: number
  noOfUnits: number
  navDate: string
}

const addFundUnitSchema = Yup.object().shape({
  name: Yup.string().required('Enter Name').label('Unit Name'),
  noOfUnits: Yup.number()
    .required('Number of Units')
    .integer('Number of Units should not be decimal')
    .label('Number of Units'),
  nav: Yup.number()
    .positive()
    .moreThan(0, 'Should be greater than 0')
    .required()
    .label('Net Asset Value'),
  navDate: Yup.string().required('Select NAV date').label('NAV Date'),
})

const addFundUnitInits: IAddFundUnitData = {
  name: 'Unit A',
  class: 'Class A',
  nav: 1,
  noOfUnits: 1,
  navDate: '',
}

export { addFundUnitInits, addFundUnitSchema }
